import { debug } from "../constants";

// APIM
export const APIPrefix =
  process.env.REACT_APP_ENV === "development" || debug
    ? "https://api-dev.kingcounty.gov/transit-mar-manager/v1/"
    : process.env.REACT_APP_ENV === "test"
      ? "https://api-test.kingcounty.gov/transit-mar-manager/v1/"
      : "https://api.kingcounty.gov/transit-mar-manager/v1/";

// Direct
export const NAPIPrefix =
  process.env.REACT_APP_ENV === "development"
    ? "https://transit-mar-manager-dev-api.azurewebsites.net/"
    : process.env.REACT_APP_ENV === "test"
      ? "https://transit-mar-manager-test-api.azurewebsites.net/"
      : "https://transit-mar-manager-api.azurewebsites.net/";

export const subscriptionKey = "Ocp-Apim-Subscription-Key";
export const subscriptionValue =
  process.env.REACT_APP_ENV === "development" || debug
    ? "c455f7f5713747bbb8ec3f1ea95656c1"
    : process.env.REACT_APP_ENV === "test"
      ? "a5b57a36daf24d93a5c8061545df581b"
      : "901a9342fa0b47668f7aa826d69801c3";

export const apiHeaders = (accessToken: string) => ({
  Authorization: "Bearer " + accessToken,
  [subscriptionKey]: subscriptionValue,
});