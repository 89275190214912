import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { DefaultButton, Separator, TextField, ActionButton, Link } from "@fluentui/react";
import { PylonType, actions as pylonActions } from "../../ducks/maintenance";
import HostInputBox from "../HostInputBox";

const AddPylon = () => {
    const [newPylons, setNewPylons] = React.useState<PylonType[]>([{
        id: '',
        location: '',
        notes: ''
    }]);
    const routerData = useSelector(
        (state: RootState) => state.router?.routerDetails
    );
    const routerSource = useSelector((state: RootState) => state.serial?.source);
    const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
    const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
    const dispatch = useDispatch();

    const addNewRecord = () => {
        setNewPylons([...newPylons, {
            id: '',
            location: '',
            notes: ''
        }])
    }

    const removeRecord = () => {
        setNewPylons(newPylons.slice(0, -1));
    }

    const updateRecord = (recordIndex: number, updateField: string) => (e: FormEvent, newValue?: string) => {
        const updatedPylons = newPylons.map((currentRecord, index) => {
            if (index === recordIndex) {
                return { ...currentRecord, [updateField]: newValue }
            } else {
                return currentRecord;
            }
        });
        setNewPylons(updatedPylons);
    }


    const completeForm = () => {
        newPylons.forEach((newPylon, index) => {
            dispatch(pylonActions.addPylon(newPylon))
            setNewPylons([{
                id: '',
                location: '',
                notes: ''
            }]);
        });
    }

    return (
        <>
            {newPylons.map((newCoachesRecord, i) => (
                <div className="maintenanceRecord" key={i}>
                    <Separator>Pylon #{i + 1}</Separator>
                    <HostInputBox inputLength={5} label="Stop ID" required onChange={updateRecord(i, 'id')} />
                    <TextField label="Location" required onChange={updateRecord(i, 'location')} />
                    <TextField label="Notes" multiline rows={3} onChange={updateRecord(i, 'notes')} />
                </div>
            ))}
            <Separator />
            <div>
                <DefaultButton onClick={() => addNewRecord()}>+ More</DefaultButton>{' '}
                {(newPylons.length > 1) && (
                    <DefaultButton onClick={() => removeRecord()}>- Less</DefaultButton>
                )}
                <DefaultButton onClick={completeForm} primary className="maintenanceFlushRight">Add Pylons</DefaultButton>
            </div>

        </>
    );
};

export default AddPylon;
