//@ts-nocheck
import {
  Html5QrcodeScanner,
  Html5QrcodeScanType,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { Icon } from "@fluentui/react/lib/Icon";
import Card from "../../components/Card";
import { TextField } from "@fluentui/react/lib/TextField";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useId } from "@fluentui/react-hooks";
import { actions as serialActions } from "../../ducks/serial";
import { actions as hostActions } from "../../ducks/hosts";
import { RootState } from "../../store/reducers";

const SerialInput = ({ source = "" }) => {
  const [useScanner, setUseScanner] = useState(false);
  const [scanned, setScanned] = useState("");
  const [serial, setSerial] = useState("");
  const [hideErrorState, setHideErrorState] = useState(false);
  const loadedSerialId = useSelector((state: RootState) => state.serial.serial);
  const lastError = useSelector((state: RootState) => state.serial.lastError);
  const isLoading = useSelector((state: RootState) => state.serial.isLoading);
  const serialFieldId = useId("serialField");
  const dispatch = useDispatch();
  const beep = new Audio("/beep.mp3");
  let html5QrcodeScanner;

  const scannerConfig = {
    fps: 10,
    qrbox: {
      width: 250,
      height: 250,
    },
    formatsToSupport: [
      Html5QrcodeSupportedFormats.CODE_128,
      Html5QrcodeSupportedFormats.CODE_39,
      Html5QrcodeSupportedFormats.EAN_8,
    ],
  };

  useEffect(() => {
    if (useScanner) {
      html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        scannerConfig,
        false
      );
      html5QrcodeScanner.render(onScanSuccess);
    } else {
      if (html5QrcodeScanner) {
        html5QrcodeScanner.clear();
      }
    }
  }, [useScanner]);

  useEffect(() => {
    if (scanned.length > 0) {
      beep.play();
      setHideErrorState(true);
      setSerial(scanned);
      serialSubmit(scanned);
    }
  }, [scanned]);

  const serialSubmit = (override) => {
    if (serial !== "" && !override) {
      dispatch(hostActions.clearHost());
      dispatch(serialActions.searchSerial(serial, source));
      setHideErrorState(false);
    }
    if (override) {
      setUseScanner(false);
      dispatch(hostActions.clearHost());
      dispatch(serialActions.searchSerial(override, source));
      setHideErrorState(false);
    }
  };

  const onScanSuccess = (decodedText) => {
    html5QrcodeScanner.clear();
    if (!useScanner || isLoading) {
      return;
    }
    setScanned(decodedText);
  };

  return (
    <Card title="Serial Search">
      <div className="serialInput">
        <div className="container__row">
          <div className="container__col-12">
            <Label htmlFor={serialFieldId}>Barcode #</Label>
          </div>
        </div>
        <div className="container__row">
          <div className="container__col-12 container__col-sm-7">
            <TextField
              invalid={lastError !== "" && hideErrorState === false}
              value={serial}
              placeholder={loadedSerialId}
              onChange={(event, newSerial) => {
                setHideErrorState(true);
                setSerial(newSerial || "");
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  serialSubmit();
                  ev.preventDefault();
                }
              }}
              onGetErrorMessage={() => {
                if (hideErrorState === false) {
                  return lastError;
                }
              }}
            />
          </div>
          <div className="container__col-12 container__col-sm-5">
            {isLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <PrimaryButton
                primary={true}
                style={{ width: "100%" }}
                className="btn"
                onClick={() => serialSubmit()}
                title="Lookup Router"
                ariaLabel="Lookup Router"
                aria-hidden={false}
              >
                <Icon iconName="Search" /> &nbsp; Lookup Router
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className="container__row">
          <div className="container__col-12">
            {useScanner && <div id="reader"></div>}
            <Toggle
              label="Use barcode scanner"
              checked={useScanner}
              inlineLabel
              onChange={() => setUseScanner(!useScanner)}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SerialInput;
