import React, { useState } from "react";
import Card from "../Card";

const NoAccess = () => {
  return (
    <div className="frame container">
      <p>&nbsp;</p>
      <Card title="No Access">
        <h1>You do not have access to this application.</h1>
        <p>
          The account you entered does not have access to this application.
          Verify account information and try again. If you continue to
          experience problems, contact your administrator.
        </p>
      </Card>
    </div>
  );
};

export default NoAccess;
