import React, { useMemo, useState } from "react";
import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { setVirtualParent } from "@fluentui/dom-utilities";
import { lastNumber } from "../../constants";
import { responsiveRow } from "../../layout";
import Card from "../Card";
import Stoplight from "../Stoplight";
import Signal from "../Signal";

const DeviceCard = ({ device, devicesHealth }) => {
  const deviceHealth = useMemo(
    () =>
      devicesHealth.filter(
        (deviceHealth) =>
          lastNumber(deviceHealth.net_device) === parseInt(device.id, 10)
      )[0] || {},
    [device]
  );
  const [deviceOpen, setDeviceOpen] = useState(true);

  let titleIcon = "PlugDisconnected";
  switch (device.connection_state) {
    case "connected":
      titleIcon = "PlugConnected";
      break;
    case "disconnected":
      titleIcon = "PlugDisconnected";
      break;
    case "unplugged":
      titleIcon = "PlugSolid";
      break;
    default:
      titleIcon = "Plug";
      break;
  }

  const deviceStatus = useMemo(() => {
    let currentStatus = "none";
    switch (device.connection_state) {
      case "connected":
        currentStatus = "green";
        break;
      case "disconnected":
        currentStatus = "red";
        break;
      case "unplugged":
        currentStatus = "red";
        break;
      default:
        currentStatus = "none";
        break;
    }
    if (
      deviceHealth?.id &&
      (currentStatus === "green" || currentStatus === "yellow")
    ) {
      if (deviceHealth.cellular_health_score > 29) {
        // Nothing
      } else if (deviceHealth.cellular_health_score > 19) {
        currentStatus = "yellow";
      } else {
        currentStatus = "red";
      }
    }
    return currentStatus;
  }, [device, deviceHealth]);

  function buildMenuItems(deviceId, enabled) {
    return [
      {
        key: "speedtest",
        text: "Speed Test",
        disabled: !enabled,
        iconProps: { iconName: "SpeedHigh" },
        onClick: () => console.log("Run Test"),
      },
    ];
  }

  const title =
    device.service_type === "WiFi"
      ? `${device.uid}`
      : `${device.model} - ${device.homecarrid}`;

  return (
    <Card
      iconName={titleIcon}
      onClick={() => setDeviceOpen(!deviceOpen)}
      collapsed={deviceOpen}
      title={[<Stoplight state={deviceStatus} />, title]}
      className="deviceRecord"
    >
      {responsiveRow("Summary", device.summary)}
      {responsiveRow("Mode", device.mode)}
      {device.homecarrid && responsiveRow("Carrier", device.carrier)}
      {responsiveRow("Type", device.service_type)}
      {device.ipv4_address && [
        responsiveRow("IPv4", device.ipv4_address),
        responsiveRow("Netmask", device.netmask),
      ]}

      {deviceHealth?.id && (
        <Signal
          percent={deviceHealth.cellular_health_score}
          category={deviceHealth.cellular_health_category}
        />
      )}
      {/* <Stoplight state="red" /> */}
      {/* <CommandBar
        items={buildMenuItems(
          device.id,
          device.connection_state === "connected"
        )}
        ariaLabel="Device Actions"
        primaryGroupAriaLabel="Device Actions"
      /> */}
    </Card>
  );
};

export default DeviceCard;
