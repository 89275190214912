import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { InteractionType, EventType } from "@azure/msal-browser";
import { sessionTTL, loginRequest } from "../../configs/authConfig";
import { actions as authActions } from "../../ducks/auth";
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

export interface AuthGateProps {
  login: React.ReactNode;
  children: React.ReactNode;
}

const AuthGate = (props: AuthGateProps) => {
  const msal = useMsal();
  const [error, setError] = useState("");
  const [sessionTimeout, setSessionTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => {
    return state.auth?.userData;
  });

  useEffect(() => {
    // This will be run on component mount
    const callbackId = msal.instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_FAILURE) {
        setError(message.error.errorMessage);
        setTimeout(() => {
          window.location.href = "/blank.html";
        }, 2000);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        msal.instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    // On MSAL change
    if (error === "") {
      try {
        dispatch(authActions.stateChange(msal));
      } catch (e) {
        console.log("Error during authentication", e);
      }
    }
  }, [msal]);

  // // Session expiration
  // useEffect(() => {
  //   if (userData?.account) {
  //     // Successful login
  //     setSessionTimeout(
  //       setTimeout(() => {
  //         if (userData?.account?.homeAccountId) {
  //           const logoutRequest = {
  //             account: msal.instance.getAccountByHomeId(
  //               userData?.account?.homeAccountId
  //             ),
  //           };
  //           console.log("timed out gate");
  //           msal.instance.logoutPopup(logoutRequest);
  //         }
  //       }, sessionTTL as number)
  //     );
  //     return () => {
  //       clearTimeout(sessionTimeout as NodeJS.Timeout);
  //     };
  //   } else {
  //     if (sessionTimeout) {
  //       // Logged out?
  //       clearTimeout(sessionTimeout as NodeJS.Timeout);
  //     }
  //   }
  // }, [userData]);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.None}
      // interactionType={InteractionType.Popup}
      // authenticationRequest={loginRequest}
      >
        {userData?.account ? (
          props.children
        ) : (
          <>
            {props.login}
            <div style={{ textAlign: "center" }}>
              Your session is invalid. It may have expired
            </div>
          </>
        )}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        {props.login}
        {error}
      </UnauthenticatedTemplate>
    </>
  );
};

export default AuthGate;
