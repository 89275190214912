import { combineReducers } from "redux";
import { reducer as authReducer } from "../ducks/auth";
import { reducer as notificationReducer } from "../ducks/notifications";
import { reducer as initReducer } from "../ducks/init";
import { reducer as serialReducer } from "../ducks/serial";
import { reducer as routerReducer } from "../ducks/router";
import { reducer as groupsReducer } from "../ducks/groups";
import { reducer as hostsReducer } from "../ducks/hosts";
import { reducer as configurationReducer } from "../ducks/configuration";
import { reducer as diagnosticsReducer } from "../ducks/diagnostics";
import { reducer as maintenanceReducer } from "../ducks/maintenance";
import { reducer as upgradesReducer } from "../ducks/upgrades";

export const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationReducer,
  init: initReducer,
  serial: serialReducer,
  router: routerReducer,
  groups: groupsReducer,
  hosts: hostsReducer,
  configuration: configurationReducer,
  diagnostics: diagnosticsReducer,
  maintenance: maintenanceReducer,
  upgrades: upgradesReducer
});

export type RootState = ReturnType<typeof rootReducer>;
