import React, { useEffect, useState, useMemo } from "react";
import {
    MessageBar,
    MessageBarType
} from '@fluentui/react';
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { responsiveRow } from "../../layout";
import { RootState } from "../../store/reducers";
import Card from "../Card";
import Stoplight from "../Stoplight";
import { useSelector } from "react-redux";

const PingCard = ({ startPing }) => {
    const diagStatus = useSelector((state: RootState) => state.diagnostics?.status);
    const speedTests = useSelector((state: RootState) => state.diagnostics?.speed_tests);
    const isPinging = useSelector((state: RootState) => state.diagnostics?.isPinging);
    const [pingCoPilot, setPingCoPilot] = useState('none');
    const [pingTouchitDDU, setPingTouchitDDU] = useState('none');
    const [pingRouterIp, setPingRouterIp] = useState('none');
    const [pingValidatorOne, setPingValidatorOne] = useState('none');
    const [pingValidatorTwo, setPingValidatorTwo] = useState('none');
    const [pingValidatorThree, setPingValidatorThree] = useState('none');
    const [pingValidatorFour, setPingValidatorFour] = useState('none');
    const [pingValidatorFive, setPingValidatorFive] = useState('none');

    useEffect(() => {
        if (speedTests.length > 0) {
            const setSpeedResult = (testResult, cbSetter) => {
                if (typeof testResult?.success === 'undefined') {
                    cbSetter('none');
                } else if (testResult?.success === false) {
                    cbSetter('red');
                } else {
                    cbSetter('green');
                }
            }

            setSpeedResult(speedTests[0], setPingCoPilot);
            setSpeedResult(speedTests[1], setPingTouchitDDU);
            setSpeedResult(speedTests[2], setPingRouterIp);
            setSpeedResult(speedTests[3], setPingValidatorOne);
            setSpeedResult(speedTests[4], setPingValidatorTwo);
            setSpeedResult(speedTests[5], setPingValidatorThree);
            setSpeedResult(speedTests[6], setPingValidatorFour);
            setSpeedResult(speedTests[7], setPingValidatorFive);

        }
    }, [speedTests]);

    const overallResult = useMemo(() => {
        if (speedTests.length === 0) {
            return 'none';
        } else {
            if (pingCoPilot === 'red' || pingTouchitDDU === 'red' || pingRouterIp === 'red' || pingValidatorOne === 'red') {
                return 'red';
            }
            if (pingCoPilot === 'green' && pingTouchitDDU === 'green' && pingRouterIp === 'green' && pingValidatorOne === 'green') {
                return 'green';
            }

        }
    }, [pingCoPilot, pingTouchitDDU, pingRouterIp, pingValidatorOne])

    const title = isPinging ? "Ping Test (running)" : "Ping Test";

    if (diagStatus?.fetching === true) {
        return (
            <Card
                title={title}
                className="pingRecord"
            >
                <MessageBar>
                    Loading certificate information...
                </MessageBar>
            </Card>
        )
    }

    if (Object.keys(diagStatus).length === 1 && diagStatus?.fetching === false) {
        return (
            <Card
                title={title}
                className="pingRecord"
            >
                <MessageBar
                    messageBarType={MessageBarType.warning}
                >
                    Ping tool is not available due to a missing certificate
                </MessageBar>
            </Card>
        )
    }

    return (
        <Card
            title={title}
            className="pingRecord"
        >
            {responsiveRow("CoPilot", [<Stoplight state={pingCoPilot} />])}
            {responsiveRow("Touchit DDU", [<Stoplight state={pingTouchitDDU} />])}
            {responsiveRow("Router IP", [<Stoplight state={pingRouterIp} />])}
            {responsiveRow("Validator 1", [<Stoplight state={pingValidatorOne} />])}
            {responsiveRow("Validator 2", [<Stoplight state={pingValidatorTwo} />])}
            {responsiveRow("Validator 3", [<Stoplight state={pingValidatorThree} />])}
            {responsiveRow("Validator 4", [<Stoplight state={pingValidatorFour} />])}
            {responsiveRow("Validator 5", [<Stoplight state={pingValidatorFive} />])}
            {responsiveRow("Overall", [<Stoplight state={overallResult} />])}
            <p></p>
            <PrimaryButton style={{ "width": "100%" }} onClick={startPing} title="Initiate Pings">Run Ping Tests</PrimaryButton>
        </Card>
    );
};

export default PingCard;
