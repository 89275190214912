import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "../ducks/auth";
import { saga as notificationSaga } from "../ducks/notifications";
import { saga as initSaga } from "../ducks/init";
import { saga as serialSaga } from "../ducks/serial";
import { saga as routerSaga } from "../ducks/router";
import { saga as groupsSaga } from "../ducks/groups";
import { saga as hostsSaga } from "../ducks/hosts";
import { saga as configurationSaga } from "../ducks/configuration";
import { saga as diagnosticsSaga } from "../ducks/diagnostics";
import { saga as maintenanceSaga } from "../ducks/maintenance";
import { saga as upgradesSaga } from "../ducks/upgrades";

const rootSaga = function* () {
  yield all([
    fork(authSaga),
    fork(notificationSaga),
    fork(initSaga),
    fork(serialSaga),
    fork(routerSaga),
    fork(groupsSaga),
    fork(hostsSaga),
    fork(configurationSaga),
    fork(diagnosticsSaga),
    fork(maintenanceSaga),
    fork(upgradesSaga)
  ]);
};

export { rootSaga };
