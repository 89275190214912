import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IIconProps } from "@fluentui/react";
import { ActionButton } from "@fluentui/react/lib/Button";
import {
  GroupedList,
  IGroup,
  IGroupHeaderProps,
  IGroupFooterProps,
} from "@fluentui/react/lib/GroupedList";
import { RootState } from "../../store/reducers";
import { actions as hostsActions } from "../../ducks/hosts";
import { actions as notificationActions } from "../../ducks/notifications";
import Card from "../../components/Card";
import { xMoreIP } from "../../constants";
import { responsiveRow } from "../../layout";

type HostIPToolProps = {};

const HostIPTool = (props: HostIPToolProps) => {
  const host = useSelector((state: RootState) => state.hosts?.hostRecord);
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();

  const validator1Text = `[FzgParam]
Fahrzeugadresse=410${host?.hostID}
MontagePlattenNr=1 
EthIP=${xMoreIP(host?.lanIP, 1)}
EthSubnet=255.255.255.224
EthGateway=${host?.lanIP}
EthDNS1=172.16.100.53
Validators=X`;

  const validator2Text = `[FzgParam]
Fahrzeugadresse=410${host?.hostID} 
MontagePlattenNr=2
EthIP=${xMoreIP(host?.lanIP, 2)}
EthSubnet=255.255.255.224
EthGateway=${host?.lanIP}
EthDNS1=172.16.100.53`;

  const validator3Text = `[FzgParam]
Fahrzeugadresse=410${host?.hostID}
MontagePlattenNr=3
EthIP=${xMoreIP(host?.lanIP, 3)}
EthSubnet=255.255.255.224
EthGateway=${host?.lanIP}
EthDNS1=172.16.100.53`;

  const validator4Text = `[FzgParam]
Fahrzeugadresse=410${host?.hostID} 
MontagePlattenNr=4
EthIP=${xMoreIP(host?.lanIP, 4)}
EthSubnet=255.255.255.224
EthGateway=${host?.lanIP}
EthDNS1=172.16.100.53`;

  const validator5Text = `[FzgParam]
Fahrzeugadresse=410${host?.hostID}
MontagePlattenNr=5
EthIP=${xMoreIP(host?.lanIP, 5)}
EthSubnet=255.255.255.224
EthGateway=${host?.lanIP}
EthDNS1=172.16.100.53`;

  const clipboardIcon: IIconProps = { iconName: "ClipboardList" };

  const showCopied = (name) => {
    dispatch(
      notificationActions.addNotification({
        type: "info",
        title: `${name} copied to clipboard.`,
      })
    );
  };

  return (
    <>
      <Card
        iconName={host.hostType === "C" ? "BusSolid" : "NetworkTower"}
        title={`IP Tool - ${host.hostType === "C" ? "Coach" : "Zone"} ${host.hostID
          }`}
        collapsed={collapsed}
        className="hostIPTool"
        onClick={() => setCollapsed((collapsed) => !collapsed)}
      >
        {host.hostType === "C" ? (
          <>
            <div className="section">
              {responsiveRow(
                "Barcode",
                host?.assetId ? host?.assetId : "Unprovisioned"
              )}
              {responsiveRow("Gateway", host?.lanIP)}
              {responsiveRow("Mask", "255.255.255.224")}
              {responsiveRow("Validator 1", xMoreIP(host?.lanIP, 1))}
              {responsiveRow("Validator 2", xMoreIP(host?.lanIP, 2))}
              {responsiveRow("Validator 3", xMoreIP(host?.lanIP, 3))}
              {responsiveRow("Validator 4", xMoreIP(host?.lanIP, 4))}
              {responsiveRow("Validator 5", xMoreIP(host?.lanIP, 5))}
              {responsiveRow("CoPilot", host?.natIP)}
              {responsiveRow("VMS Streamvault Gateway", xMoreIP(host?.vmsNet, 1))}
              {responsiveRow("VMS Streamvault Server", xMoreIP(host?.vmsNet, 2))}
              {/* {responsiveRow(
                "VMS Streamvault Gateway",
                xMoreIP(host?.vmsNet, 1)
              )} */}
              {responsiveRow("VMS Mask", "255.255.255.248")}
            </div>
            <div className="section">
              <b>Validator 1</b>
              <ActionButton
                iconProps={clipboardIcon}
                className="copyButton container__col-offset-4 container__col-xs-offset-6 container__col-lg-offset-8 container__col-xl-offset-9"
                onClick={() => {
                  navigator.clipboard.writeText(validator1Text);
                  showCopied("Validator 1");
                }}
              >
                Copy
              </ActionButton>
              <fieldset>
                <pre>{validator1Text}</pre>
              </fieldset>
            </div>
            <div className="section">
              <b>Validator 2</b>
              <ActionButton
                iconProps={clipboardIcon}
                className="copyButton container__col-offset-4 container__col-xs-offset-6 container__col-lg-offset-8 container__col-xl-offset-9"
                onClick={() => {
                  navigator.clipboard.writeText(validator2Text);
                  showCopied("Validator 2");
                }}
              >
                Copy
              </ActionButton>
              <fieldset>
                <pre>{validator2Text}</pre>
              </fieldset>
            </div>
            <div className="section">
              <b>Validator 3</b>
              <ActionButton
                iconProps={clipboardIcon}
                className="copyButton container__col-offset-4 container__col-xs-offset-6 container__col-lg-offset-8 container__col-xl-offset-9"
                onClick={() => {
                  navigator.clipboard.writeText(validator3Text);
                  showCopied("Validator 3");
                }}
              >
                Copy
              </ActionButton>
              <fieldset>
                <pre>{validator3Text}</pre>
              </fieldset>
            </div>
            <div className="section">
              <b>Validator 4</b>
              <ActionButton
                iconProps={clipboardIcon}
                className="copyButton container__col-offset-4 container__col-xs-offset-6 container__col-lg-offset-8 container__col-xl-offset-9"
                onClick={() => {
                  navigator.clipboard.writeText(validator4Text);
                  showCopied("Validator 4");
                }}
              >
                Copy
              </ActionButton>
              <fieldset>
                <pre>{validator4Text}</pre>
              </fieldset>
            </div>
            <div className="section">
              <b>Validator 5</b>
              <ActionButton
                iconProps={clipboardIcon}
                className="copyButton container__col-offset-4 container__col-xs-offset-6 container__col-lg-offset-8 container__col-xl-offset-9"
                onClick={() => {
                  navigator.clipboard.writeText(validator5Text);
                  showCopied("Validator 5");
                }}
              >
                Copy
              </ActionButton>
              <fieldset>
                <pre>{validator5Text}</pre>
              </fieldset>
            </div>
          </>
        ) : (
          <div className="section">
            {responsiveRow("Zone", host.hostID)}
            {responsiveRow(
              "Barcode",
              host?.assetId ? host?.assetId : "Unprovisioned"
            )}
            {responsiveRow("Location", host?.location)}
            {responsiveRow("SAFTP IP", xMoreIP(host?.lanIP, 1))}
            {responsiveRow("MASK", "255.255.255.224")}
            {responsiveRow("Gateway", host?.lanIP)}
            {responsiveRow("RTIS", xMoreIP(host?.lanIP, 2))}
            {responsiveRow("targetIP", `${xMoreIP(host?.lanIP, 2)}:FFFFFFE0`)}
            {responsiveRow("gatewayIP", host?.lanIP)}
            <fieldset>
              <legend>Notes</legend>
              <p>{host?.notes}</p>
            </fieldset>
          </div>
        )}
      </Card>
    </>
  );
};

export default HostIPTool;
