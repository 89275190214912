import React from "react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";

const Stoplight = ({ state = "none" }) => {
  return (
    <div className="stoplight">
      <FontIcon iconName="CircleFill" className="backing" />
      {state === "green" && (
        <>
          <FontIcon iconName="CircleFill" className="green" />
        </>
      )}
      {state === "yellow" && (
        <>
          <FontIcon iconName="CircleFill" className="yellow" />
        </>
      )}
      {state === "red" && (
        <>
          <FontIcon iconName="CircleFill" className="red" />
        </>
      )}
      {state === "none" && (
        <>
          <FontIcon iconName="CircleFill" className="none" />
        </>
      )}
    </div>
  );
};

export default Stoplight;
