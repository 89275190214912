import React from "react";
import ReactDOM from "react-dom";
import { initializeIcons } from "@fluentui/react";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

initializeIcons();

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
