import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useMsal } from "@azure/msal-react";
import { IconButton, ActionButton } from "@fluentui/react/lib/Button";
import { useNavigate } from "react-router-dom";
import { debug } from "../../constants";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  IButtonStyles,
  Layer,
  Link,
  FontWeights,
  Panel,
} from "@fluentui/react";
import packagej from "../../../package.json";

interface NavbarProps {
  userName?: string;
  homeAccountId?: string;
}
const NavBar = (props: NavbarProps) => {
  const [isPanelOpen, setPanelOpen] = useState<Boolean>(false);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const environmentIdentifier = process.env.REACT_APP_ENV === "development" || debug
  ? "Development"
  : process.env.REACT_APP_ENV === "test"
  ? "Testing"
  : "Production";

  const environmentClass = process.env.REACT_APP_ENV === "development" || debug
  ? "dev"
  : process.env.REACT_APP_ENV === "test"
  ? "test"
  : "prod";

  const iconStyles: IButtonStyles = {
    root: {
      color: "blue",
      fontSize: 16,
      fontWeight: FontWeights.regular,
    },
  };

  const signout = () => {
    if (props?.homeAccountId) {
      const logoutRequest = {
        account: instance.getAccountByHomeId(props?.homeAccountId),
      };
      instance.logoutPopup(logoutRequest);
    }
  };

  return (
    <div>
      <Layer>
        <div className={`navbar ${environmentClass}`}>
          <div style={{ margin: "0 20px" }}>
            <Icon iconName="Bus" className="headerLogo" />
            <Link className="titleLink" href="/">
              MAR Config v{packagej.version} - {environmentIdentifier}
            </Link>
            <div style={{ float: "right" }} className="accountLogo">
              <IconButton
                iconProps={{ iconName: "AccountManagement" }}
                onClick={() => setPanelOpen(!isPanelOpen)}
                title="Add"
                ariaLabel="Add"
                aria-hidden={false}
              />
            </div>
          </div>
        </div>
      </Layer>

      {isPanelOpen && (
        <Panel
          isOpen
          hasCloseButton
          headerText={props.userName}
          onDismiss={() => setPanelOpen(false)}
        >
          <Stack>
            <ActionButton
              iconProps={{ iconName: "ViewDashboard" }}
              onClick={() => {
                setPanelOpen(false);
                navigate("/");
              }}
              styles={iconStyles}
              title="Dashboard"
              ariaLabel="Dashboard"
              aria-hidden={false}
            >
              Dashboard
            </ActionButton>
          </Stack>
          <Stack>
            <ActionButton
              iconProps={{ iconName: "List" }}
              onClick={() => {
                setPanelOpen(false);
                navigate("/changelog");
              }}
              styles={iconStyles}
              title="View Changelog"
              ariaLabel="View Changelog"
              aria-hidden={false}
            >
              View Changelog
            </ActionButton>
          </Stack>

          <Stack>
            <ActionButton
              iconProps={{ iconName: "SignOut" }}
              onClick={() => {
                setPanelOpen(false);
                if (debug) {
                  alert("Signout");
                } else {
                  signout();
                }
              }}
              styles={iconStyles}
              title="Sign out"
              ariaLabel="Sign out"
              aria-hidden={false}
            >
              Sign out
            </ActionButton>
          </Stack>
        </Panel>
      )}
    </div>
  );
};

export default NavBar;
