import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Label } from "@fluentui/react/lib/Label";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import Card from "../../components/Card";
import { actions as hostActions } from "../../ducks/hosts";
import { actions as notificationActions } from "../../ducks/notifications";

const HostInput = ({ source = "" }) => {
  const hostIdField = useId("hostIdField");
  const [host, setHost] = useState<string>("");
  const [hideErrorState, setHideErrorState] = useState<boolean>(false);
  const loadedHostId = useSelector((state: RootState) => state.hosts.hostId);
  const isLoading = useSelector((state: RootState) => state.hosts.isLoading);
  const lastError = useSelector((state: RootState) => state.hosts.lastError);
  const dispatch = useDispatch();

  const hostSubmit = () => {
    dispatch(hostActions.clearHost());
    if (host !== "") {
      dispatch(hostActions.searchHost(host, source));
      setHideErrorState(false);
    } else {
      dispatch(
        notificationActions.addNotification({
          type: "error",
          title: `Please enter a valid Host`,
        })
      );
    }
  };

  return (
    <Card title="Coach Search" className="hostInput">
      <div className="section">
        <div className="container__row">
          <div className="container__col_12">
            <Label htmlFor={hostIdField}>Host Id</Label>
          </div>
        </div>

        <div className="container__row">
          <div className="container__col-12 container__col-sm-7">
            <TextField
              invalid={lastError !== "" && hideErrorState === false}
              placeholder={loadedHostId}
              onChange={(event, newHostId) => {
                setHost(newHostId || "");
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  hostSubmit();
                  ev.preventDefault();
                }
              }}
              onGetErrorMessage={() => {
                return "";
              }}
            />
          </div>
          <div className="container__col-12 container__col-sm-5">
            {isLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <PrimaryButton
                primary={true}
                style={{ width: "100%" }}
                className="btn"
                onClick={hostSubmit}
                title="Lookup Router"
                ariaLabel="Lookup Router"
                aria-hidden={false}
              >
                <Icon iconName="Search" /> &nbsp; Lookup Host
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HostInput;
