import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import {
  capitalize,
  lastNumber,
  isValidHost,
  routerType,  
} from "../../constants";
import { actions as routerActions } from "../../ducks/router";
import Card from "../../components/Card";
import { responsiveRow } from "../../layout";

type RouterRecordProps = {};
type TagType = {
  text: string;
  color: string;
};

const RouterRecord = (props: RouterRecordProps) => {
  const router = useSelector((state: RootState) => state.router?.routerDetails);
  const serial = useSelector((state: RootState) => state.serial);
  const groups = useSelector((state: RootState) => state.groups.groups);

  const dispatch = useDispatch();

  const latestGroup = () => {
    const routerGroupId = lastNumber(router.group);
    let highestFirmware = 0;
    let latestGroup = 0;
    groups.forEach((cur) => {
      const firmwareVersion = lastNumber(cur.target_firmware);
      if (firmwareVersion > highestFirmware) {
        highestFirmware = firmwareVersion;
        latestGroup = parseInt(cur.id);
      }
    });
    return routerGroupId === latestGroup;
  };

  let tags: TagType[] = [];

  if (router === null) {
    // Do not display if no router is loaded
    return null;
  }

  // Connect group to router if possible
  const groupId = lastNumber(router?.group);
  const routerGroupArray = groups.filter((group) => {
    return parseInt(group.id) === groupId;
  });

  if (router.state === "online") {
    tags.push({
      text: "Online",
      color: "green",
    });
  } else {
    tags.push({
      text: "Offline",
      color: "red",
    });
  }

  if (router?.config_status) {
    tags.push({
      text:
        router.config_status.charAt(0).toUpperCase() +
        router.config_status.toLowerCase().slice(1),
      color: router.config_status === "synched" ? "green" : "brown",
    });
  }

  if (router.upgrade_pending) {
    tags.push({
      text: "Upgrade Pending",
      color: "orange",
    });
  }

  // if (!latestGroup()) {
  //   tags.push({
  //     text: "Upgradable",
  //     color: "gray",
  //   });
  // }

  return (
    <>
      {!!router?.id ? (
        <>
          <Card
            onClose={() => dispatch(routerActions.clearRouter())}
            iconName="WifiEthernet"
            title={[
              `${capitalize(router?.device_type)} ${router?.full_product_name}`,
              ...tags.map((tag, index) => {
                return (
                  <>
                    &nbsp;
                    <span
                      className="tag"
                      key={`tag-${index}`}
                      style={{ backgroundColor: tag.color }}
                    >
                      {tag.text}
                    </span>
                  </>
                );
              }),
            ]}
            className="routerRecord"
          >
            <div className="section">
              {responsiveRow(
                "Barcode",
                serial?.serial === "" ||
                  typeof serial?.serial === "undefined" ? (
                  <>&mdash;</>
                ) : (
                  serial.serial
                )
              )}

              {responsiveRow(
                routerType(router) === "Coach" ? "Coach" : "Zone",
                isValidHost(router?.name)
                  ? lastNumber(router?.name)
                  : "Unprovisioned"
              )}
              {responsiveRow("MAC Address", router?.mac)}
              {responsiveRow("Group", lastNumber(router?.group))}
            </div>            
          </Card>
        </>
      ) : (
        <div>No Router Found</div>
      )}
    </>
  );
};

export default RouterRecord;
