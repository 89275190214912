import { createReducer, action } from "typesafe-actions";
import { debug } from "../constants";

let globalNotificationIncrementer = 0;

export interface Notification {
  type: "warning" | "info" | "error" | "success";
  title: string;
  timeout?: number;
  id?: number;
}

// State Type
type State = {
  notifications: Notification[];
};

// Initial State
const initialState: State = {
  notifications: [],
};

const debugState: State = {
  notifications: [],
};

// Actions
const actionTypes = {
  addNotification: "NOTIFICATION_ADD",
  closeNotification: "NOTIFICATION_CLOSE",
};
const actions = {
  addNotification: (notification: Notification) =>
    action(actionTypes.addNotification, {
      notification,
    }),
  closeNotification: (id: number) =>
    action(actionTypes.closeNotification, {
      id,
    }),
};

// Reducer
const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.addNotification]: (state, action) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {
          timeout: 3000, // Default timeout = 3s
          ...action.payload.notification,
          id: globalNotificationIncrementer++,
        },
      ].slice(-5), // Only use latest 5 notifications
    };
  },
  [actionTypes.closeNotification]: (state, action) => {
    // Remove notification from array
    let updatedNotifications = [...state.notifications].filter(
      (item) => item.id !== action.payload.id
    );
    return {
      ...state,
      notifications: updatedNotifications,
    };
  },
});

// Saga
const saga = function* () {};

export { reducer, actions, saga };
