import { Icon, Link } from "@fluentui/react";
import { FC, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { MessageBar, MessageBarType } from "@fluentui/react";
import {
  Notification as NotificationType,
  actions as notificationActions,
} from "../../ducks/notifications";

interface INotificationProps {
  notification: NotificationType;
  onClose: Function;
}

export const Notifications = () => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );
  const dispatch = useDispatch();
  const closeNotification = (id) => {
    dispatch(notificationActions.closeNotification(id));
  };

  if (notifications.length > 0) {
    return (
      <div className="notifications">
        {notifications.map((notification: NotificationType, key: Number) => {
          return (
            <div key={`Notification-${key}`} className="notification">
              <Notification
                onClose={() => closeNotification(notification.id)}
                notification={notification}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

const Notification: FC<INotificationProps> = ({ onClose, notification }) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setTimer(
      setTimeout(() => {
        onClose();
      }, notification.timeout as number)
    );
    return () => {
      clearTimeout(timer as NodeJS.Timeout);
    };
  }, []);

  return (
    <MessageBar
      messageBarType={
        {
          warning: MessageBarType.warning,
          error: MessageBarType.error,
          info: MessageBarType.info,
          success: MessageBarType.success,
        }[notification.type]
      }
      onDismiss={() => {
        clearTimeout(timer as NodeJS.Timeout);
        onClose();
      }}
      dismissButtonAriaLabel="Close"
      truncated={true}
    >
      {notification.title}
    </MessageBar>
  );
};

export default Notification;
