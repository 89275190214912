import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import NavBar from "./NavBar";

const Header = () => {
  const userAccount = useSelector(
    (state: RootState) => state.auth.userData.account
  );

  return (
    <div className="header" style={{ marginTop: "60px" }}>
      <NavBar
        userName={userAccount?.name}
        homeAccountId={userAccount?.homeAccountId}
      />
    </div>
  );
};
export default Header;
