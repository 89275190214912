import { all, fork, takeEvery, takeLatest, put, call, delay } from "redux-saga/effects";
import { IMsalContext } from "@azure/msal-react";
import { InteractionStatus, AccountInfo } from "@azure/msal-browser";
import { createReducer, action } from "typesafe-actions";
import { tokenRequest, graphConfig } from "../configs/authConfig";
import { debug } from "../constants";
import { SagaIterator } from "redux-saga";

export enum FlowState {
  None,
  Initialized,
  Login,
  Accepted,
  Tokened,
  Success,
  Fail,
}

interface ExtendedAccountInfo extends AccountInfo {
  idTokenClaims: any;
}

// Types
type UserData = {
  account: ExtendedAccountInfo | undefined;
};

type State =
  | {
    flowState: number;
    accessToken: string;
    userData: UserData;
  }
  | undefined;

// Initial State
const initialState: State = {
  flowState: FlowState.None,
  accessToken: "",
  userData: {
    account: undefined,
  },
};

// Debug State
const debugState: State = {
  flowState: FlowState.Success,
  accessToken: "None",
  userData: {
    account: {
      homeAccountId: "debug",
      environment: "debug",
      tenantId: "debug",
      localAccountId: "debug",
      username: "debuguser@nowhere.com",
      name: "User, Debug",
      idTokenClaims: {
        roles: ["Transit-MAR-Manager-SysAdmin", "Transit-MAR-Manager-Debug"],
      },
    },
  },
};

// Actions
const actionTypes = {
  stateChange: "AUTH_STATE_CHANGE",
  authSuccess: "AUTH_SUCCESS",
  setAccount: "AUTH_SET_ACCOUNT",
  setAccessToken: "AUTH_SET_ACCESSTOKEN",
  setGroups: "AUTH_SET_GROUPS",
};

const actions = {
  stateChange: (msal: IMsalContext) =>
    action(actionTypes.stateChange, { msal }),
  setAccount: (account: AccountInfo) =>
    action(actionTypes.setAccount, { account }),
  setAccessToken: (accessToken: string) =>
    action(actionTypes.setAccessToken, { accessToken }),
  setGroups: (groups: string[]) => action(actionTypes.setGroups, { groups }),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.stateChange]: (state, action) => {
    if (action.payload?.msal?.inProgress === InteractionStatus.Startup) {
      return { ...state, flowState: FlowState.Initialized };
    }
    if (action.payload?.msal?.inProgress === InteractionStatus.Login) {
      return { ...state, flowState: FlowState.Login };
    }
    return state;
  },

  [actionTypes.setAccount]: (state, action) => ({
    ...state,
    flowState: FlowState.Accepted,
    userData: {
      ...state.userData,
      account: action.payload.account,
    },
  }),

  [actionTypes.setAccessToken]: (state, action) => ({
    ...state,
    flowState: FlowState.Tokened,
    accessToken: action.payload.accessToken,
  }),

  [actionTypes.setGroups]: (state, action) => ({
    ...state,
    flowState: FlowState.Success,
    userData: {
      ...state.userData,
      groups: action.payload.groups,
    },
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([takeEvery(actionTypes.stateChange, waitForAccount as any)]);
};

function* waitForAccount(action: {
  type: typeof actions.stateChange;
  payload: { msal: IMsalContext };
}) {
  if (
    action.payload.msal.accounts.length > 0 &&
    action.payload.msal.inProgress === InteractionStatus.None
  ) {
    yield put(actions.setAccount(action.payload.msal.accounts[0]));
    try {
      const response = yield call(
        [
          action.payload.msal.instance,
          action.payload.msal.instance.acquireTokenSilent,
        ],
        {
          ...tokenRequest,
          account: action.payload.msal.accounts[0],
        }
      );
      yield put(actions.setAccessToken(response.accessToken));
    } catch (e) {
      // If we can't aquire an access token silently, then lets ask for one in a popup.
      console.log("can not aquire token silent", e);
      const response = yield call(
        [
          action.payload.msal.instance,
          action.payload.msal.instance.acquireTokenPopup,
        ],
        {
          ...tokenRequest,
          account: action.payload.msal.accounts[0],
        }
      );
      yield put(actions.setAccessToken(response.accessToken));
    }
  }
}

export { reducer, actions, saga, actionTypes };
