import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { Stack } from "@fluentui/react/lib/Stack";
import { List } from "@fluentui/react/lib/List";
import Frame from "../../components/Frame";
import Card from "../../components/Card";
import Markdown from "markdown-to-jsx";

const ChangeLog = () => {
  const apiInfo = useSelector((state: RootState) => state.init.apiInfo);
  const userData = useSelector((state: RootState) => state.auth.userData);
  return (
    <Frame>
      <Card title="Changelog">
        <Markdown>{`

## v1.5.1
- UAT bug fixes on maintenance page        

## v1.5.0
- Added upgrade tool

## v1.4.0
- Added maintenance page
- Added ability to add and remove hardware

## v1.3.5
- Added diagnostics screen
- Added Host IP Records

## v1.3.0
- Added Ping Tool
- Added Certificate checks
- Added Configuration Selection Dropdown

## v1.2.5
- Added Host IP Tool
- Added placeholder in search input boxes for hosts and routers

----

## v1.2.0
- Added Diagnostics Screen
- Added Device diagnostics screens

----

## v1.1.0 
- UX Update. Changed Fonting
- Changed CTA Button Locations

## v1.0.0
- Official release of application.
- More template fixes and minor UI changes

---

## v0.9.8
- Update BGP Slash notation for 1700s

---

## v0.9.7
- Automatic Router & Host refreshing

## v0.9.6
- Unique configuration for pylons

---

## v0.9.5
- Added configuration status badges
- Support for unsuspending routers during configuration


## v0.9.3
- Change to configuration
- Can deprovision multiple routers

---

## v0.9.0
- Reworked configuration template system. 
- Adapted errors for App Insights
- Removed uneeded tags
- Fixed router images

---

## v0.8.0
- Many fixes to configuration template system
- Bifercation of configuration template based on VMSNets
- General bug fixes on flow
- Better error control and notification system

## v0.7.0
- Bug fixes
- Working provisioning flow

---

## v0.6.0
- First draft of router provisioning flow
- Search readmittance after record updates

---        

## v0.5.5
- First draft of router deprovisioning flow
- Updated device type detection to be more accurate

## v0.5.2
- First round of UAT
- Renamed many labels
- Change in UI

---        

## v0.3.0
- Refactor of flow
- General update to all UI

---

## v0.2.2
- Provisioning flow
- Confirmation dialogs
- Updated Iconography

---

## v0.2.0
- Added 7-in-1 Styling and theme
- Added Host Search and Host Record UI
- Changed authentication cache style
- Added better control for logging in and out

---

## v0.1.5
- Added Router Record Card
- Added HostID Lookup
- Additional Styling Updates

---

## v0.1.0
- Added Notification System
- Added Fluent UI
- Added Changelog

## v0.0.1
- Added Authentication System
- Added Basic UI Framework
- Added State Managment

---

** API INFO ** : \`${apiInfo}\`

`}</Markdown>
      </Card>
    </Frame>
  );
};

export default ChangeLog;
