import { createReducer, action } from "typesafe-actions";
import { all, takeEvery, put, call, select, delay } from "redux-saga/effects";
import { NAPIPrefix, APIPrefix, apiHeaders } from "../configs/apiConfig";
import axios from "axios";
import { debug } from "../constants";
import { actions as notificationActions } from "./notifications";
import { SagaIterator } from "redux-saga";

export const maintenanceURL = `${APIPrefix}`;

// Types
export type HardwareType = {
    barcode: string;
    mac: string;
}

export type RemoveHardwareType = {
    barcode: string;
}

export type CoachType = {
    id: string;
}

export type PylonType = {
    id: string;
    location: string;
    notes: string;
}

type State = {};

// Initial State
const initialState: State = {

};

// Debug State
const debugState: State = {

};

// Actions
const actionTypes = {
    addHardware: 'MAINTENANCE_ADD_HARDWARE',
    addHardwareSuccess: 'MAINTENANCE_ADD_HARDWARE_SUCCESS',
    addHardwareFailure: 'MAINTENANCE_ADD_HARDWARE_FAILURE',
    removeHardware: 'MAINTENANCE_REMOVE_HARDWARE',
    removeHardwareSuccess: 'MAINTENANCE_REMOVE_HARDWARE_SUCCESS',
    removeHardwareFailure: 'MAINTENANCE_REMOVE_HARDWARE_FAILURE',
    addCoach: 'MAINTENANCE_ADD_COACH',
    addCoachSuccess: 'MAINTENANCE_ADD_COACH_SUCCESS',
    addCoachFailure: 'MAINTENANCE_ADD_COACH_FAILURE',
    addPylon: 'MAINTENANCE_ADD_PYLON',
    addPylonSuccess: 'MAINTENANCE_ADD_PYLON_SUCCESS',
    addPylonFailure: 'MAINTENANCE_ADD_PYLON_FAILURE'
};

const actions = {
    addHardware: (hardwareDetails: any) =>
        action(actionTypes.addHardware, { hardwareDetails }),
    addHardwareSuccess: () => action(actionTypes.addCoachSuccess),
    addHardwareFailure: () => action(actionTypes.addCoachFailure),
    removeHardware: (hardwareDetails: any) =>
        action(actionTypes.removeHardware, { hardwareDetails }),
    removeHardwareSuccess: () => action(actionTypes.removeHardwareSuccess),
    removeHardwareFailure: () => action(actionTypes.removeHardwareFailure),
    addCoach: (coachDetails: any) =>
        action(actionTypes.addCoach, { coachDetails }),
    addCoachSuccess: () => action(actionTypes.addCoachSuccess),
    addCoachFailure: () => action(actionTypes.addCoachFailure),
    addPylon: (pylonDetails: any) =>
        action(actionTypes.addPylon, { pylonDetails }),
    addPylonSuccess: () => action(actionTypes.addPylonSuccess),
    addPylonFailure: () => action(actionTypes.addPylonFailure)
};

const reducer = createReducer(debug ? debugState : initialState, {

});

// Saga
const saga = function* (): SagaIterator {
    yield all([takeEvery(actionTypes.addHardware, waitAddHardware as any)]);
    yield all([takeEvery(actionTypes.removeHardware, waitRemoveHardware as any)]);
    yield all([takeEvery(actionTypes.addCoach, waitAddCoach as any)]);
    yield all([takeEvery(actionTypes.addPylon, waitAddPylon as any)]);
};

function* waitAddHardware(action: {
    type: typeof actions.addHardware;
    payload: { hardwareDetails: any };
}) {
    try {
        const accessToken = yield select((state) => state.auth.accessToken);
        const { data, status } = yield call(
            axios.post,
            maintenanceURL + `serial/${action.payload.hardwareDetails.barcode}/${action.payload.hardwareDetails.mac}`,
            {},
            {
                headers: apiHeaders(accessToken),
            }
        );

        if (status !== 200) {
            yield put(actions.addHardwareFailure());
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `There was a problem adding ${action.payload.hardwareDetails.barcode}`,
                })
            );
        } else {
            yield put(
                notificationActions.addNotification({
                    type: "success",
                    title: `Added hardware ${action.payload.hardwareDetails.barcode}`,
                })
            );
            yield put(actions.addHardwareSuccess());
        }
    } catch (e: any) {
        if (e.response?.status === 404) {
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `Hardware ${action.payload.hardwareDetails.barcode} not found`,
                })
            );
            yield put(actions.addHardwareFailure());
        } else {
            yield put(actions.addHardwareFailure());
        }
    }
}

function* waitRemoveHardware(action: {
    type: typeof actions.removeHardware;
    payload: { hardwareDetails: any };
}) {
    try {
        const accessToken = yield select((state) => state.auth.accessToken);
        const { data, status } = yield call(
            axios.delete,
            maintenanceURL + `serial/${action.payload.hardwareDetails.barcode}?softdelete=true`,
            {
                headers: apiHeaders(accessToken),
            }
        );

        if (status !== 200) {
            yield put(actions.removeHardwareFailure());
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `There was a problem removing ${action.payload.hardwareDetails.barcode}`,
                })
            );
        } else {
            yield put(
                notificationActions.addNotification({
                    type: "success",
                    title: `Removed hardware ${action.payload.hardwareDetails.barcode}`,
                })
            );
            yield put(actions.removeHardwareSuccess());
        }
    } catch (e: any) {
        if (e.response?.status === 404) {
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `Hardware ${action.payload.hardwareDetails.barcode} not found`,
                })
            );
            yield put(actions.removeHardwareFailure());
        } else {
            yield put(actions.removeHardwareFailure());
        }
    }
}

function* waitAddCoach(action: {
    type: typeof actions.addCoach;
    payload: { coachDetails: any };
}) {
    const accessToken = yield select((state) => state.auth.accessToken);
    try {
        const precheck = yield call(
            axios.get,
            maintenanceURL + `hosts/${action.payload.coachDetails.id}`,
            {
                headers: apiHeaders(accessToken),
            }
        );
        yield put(
            notificationActions.addNotification({
                type: "error",
                title: `Coach ${action.payload.coachDetails.id} already exists`,
            }));
        return;
    } catch (e) {
        // blacnk catch
    }

    try {
        const { data } = yield call(
            axios.post,
            maintenanceURL + `hosts/${action.payload.coachDetails.id}/C`,
            {},
            {
                headers: apiHeaders(accessToken),
            }
        );

        if (!data) {
            yield put(actions.addCoachFailure());
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `There was a problem adding ${action.payload.coachDetails.id}`,
                })
            );
        } else {
            yield put(
                notificationActions.addNotification({
                    type: "success",
                    title: `Added coach ${action.payload.coachDetails.id}`,
                })
            );
            yield put(actions.addCoachSuccess());
        }
    } catch (e: any) {
        if (e.response?.status === 404) {
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `Unable to add coach ${action.payload.coachDetails.id}`,
                })
            );
            yield put(actions.addCoachFailure());
        } else {
            yield put(actions.addCoachFailure());
        }
    }
}

function* waitAddPylon(action: {
    type: typeof actions.addPylon;
    payload: { pylonDetails: any };
}) {
    const accessToken = yield select((state) => state.auth.accessToken);
    try {
        const precheck = yield call(
            axios.get,
            maintenanceURL + `hosts/${action.payload.pylonDetails.id}`,
            {
                headers: apiHeaders(accessToken),
            }
        );
        yield put(
            notificationActions.addNotification({
                type: "error",
                title: `Stop ${action.payload.pylonDetails.id} already exists`,
            }));
        return;
    } catch (e) {
        // blank catch
    }
    try {
        const { data } = yield call(
            axios.post,
            maintenanceURL + `hosts/${action.payload.pylonDetails.id}/R?location=${encodeURIComponent(action.payload.pylonDetails.location)}&notes=${encodeURIComponent(action.payload.pylonDetails.notes)}`,
            {},
            {
                headers: apiHeaders(accessToken),
            }
        );

        if (!data) {
            yield put(actions.addPylonFailure());
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `There was a problem adding ${action.payload.pylonDetails.id}`,
                })
            );
        } else {
            yield put(
                notificationActions.addNotification({
                    type: "success",
                    title: `Added Stop ${action.payload.pylonDetails.id}`,
                })
            );
            yield put(actions.addPylonSuccess());
        }
    } catch (e: any) {
        if (e.response?.status === 404) {
            yield put(
                notificationActions.addNotification({
                    type: "error",
                    title: `Unable to add Stop ${action.payload.pylonDetails.id}`,
                })
            );
            yield put(actions.addPylonFailure());
        } else {
            yield put(actions.addPylonFailure());
        }
    }
}


export { reducer, actions, saga };
