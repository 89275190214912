import React from "react";
import { Route, Routes } from "react-router";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import store from "./store/store";
import { debug } from "./constants";
import { msalConfig } from "./configs/authConfig";
import { reactPlugin } from "./configs/appInsights";
import ErrorPage from "./pages/Error";
import AuthGate from "./components/AuthGate";
import Login from "./pages/Login";
import ChangeLog from "./pages/Changelog";
import Dashboard from "./pages/Dashboard";
import "./styles/main.scss";

export const App = () => {
  if (window && window.location.href === "/blank") {
    return <></>;
  }

  if (debug) {
    return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/changelog" element={<ChangeLog />} />
          </Routes>
        </Router>
      </Provider>
    );
  }

  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <ErrorPage />}
        appInsights={reactPlugin}
      >
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <Router>
              <AuthGate login={<Login />}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/changelog" element={<ChangeLog />} />
                </Routes>
              </AuthGate>
            </Router>
          </MsalProvider>
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};
