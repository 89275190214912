// Mapping for NCMGroupIDs to Configuration Templates


const configMap = (ncmGroupId: number, hosttype?: string): string => {
  switch (ncmGroupId) {
    case 317696: // Default Coach
      return "1700"
      break;
    case 314975: // All Pylons
      return "900"
      break;
    case 395429: // New VMS Group
      return "1700vms-2"
      break;
    default:
      if (hosttype === "C") {
        return "1700";
      } else {
        return "900";
      }
      break;
  }
}

const templates = {
  "1700": require("./routerTemplates/ibr1700-no-vms.json"),
  "1700vms": require("./routerTemplates/ibr1700-vms.json"),
  "1700vms-2": require("./routerTemplates/ibr1700-vms-2.json"),
  "900": require("./routerTemplates/ibr900.json"),
};

const buildTemplate = (targetGroup: number, values = {}) => {
  const rawJSON = templates[configMap(targetGroup)];
  let jsonAsString = JSON.stringify(rawJSON);
  Object.keys(values).forEach((valueKey) => {
    jsonAsString = jsonAsString.replaceAll(`{{${valueKey}}}`, values[valueKey]);
  });

  return JSON.parse(jsonAsString);
};

export default buildTemplate;
