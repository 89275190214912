import React, { ReactElement, FC } from "react";
import { IconButton } from "@fluentui/react/lib/Button";
import { FontIcon } from "@fluentui/react";

interface ICardProps {
  title: string | ReactElement | (string | ReactElement)[];
  iconName?: string;
  onClick?: () => void;
  ctaText?: string;
  className?: string;
  collapsed?: boolean;
  onClose?: () => void;
}

const Card: FC<ICardProps> = ({
  title,
  children,
  className,
  onClick,
  iconName,
  collapsed,
  onClose,
}) => {
  return (
    <div className={`card ${className || ""} ${collapsed ? "collapsed" : ""}`}>
      <div
        className="card-title-wrapper"
        onClick={() => {
          onClick && onClick();
        }}
      >
        <span className="card-title">
          {iconName ? <FontIcon iconName={iconName} /> : ""}
          {title}
          {onClick ? (
            <IconButton
              className="cardClose"
              iconProps={{
                iconName: !collapsed
                  ? "CollapseContentSingle"
                  : "ExploreContentSingle",
              }}
              title="Toggle Collapse"
              ariaLabel="Toggle Collapse"
            />
          ) : null}
          {onClose ? (
            <IconButton
              className="cardClose"
              iconProps={{ iconName: "StatusCircleErrorX" }}
              title="Close Card"
              ariaLabel="Close Card"
              onClick={onClose}
            />
          ) : null}
        </span>
      </div>
      {!collapsed && <div className={`card-content`}>{children}</div>}
    </div>
  );
};

export default Card;
