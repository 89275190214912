import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DefaultButton } from "@fluentui/react/lib/Button";
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react';
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Label } from "@fluentui/react/lib/Label";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import Card from "../../components/Card";
import { useId } from "@fluentui/react-hooks";
import { RootState } from "../../store/reducers";
import { isValidHost, lastNumber } from "../../constants";
import { actions as configurationActions } from "../../ducks/configuration";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";

const ProvisionButton = () => {
  const host = useSelector((state: RootState) => state.hosts?.hostRecord);
  const router = useSelector((state: RootState) => state.router?.routerDetails);
  const configuration = useSelector((state: RootState) => state.configuration);
  const availableGroups = useSelector((state: RootState) => state.groups?.availableGroups);
  const serial = useSelector((state: RootState) => state.serial);
  const [upgrade, setUpgrade] = useState(true);
  const [hideDialogRouter, setHideDialogRouter] = useState(true);
  const [hideDialogHost, setHideDialogHost] = useState(true);
  const [hideDialogGroup, setHideDialogGroup] = useState(true);
  const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
  const dispatch = useDispatch();
  const configDropdownId = useId("configDropdownId");

  const isCompatible = () =>
    (host.hostType === "R" && lastNumber(router.product) === 35) ||
    (host.hostType === "C" && lastNumber(router.product) === 40);

  const doProvision = (force = false, selectedConfiguration) => {
    return () => {
      if (!force) {
        if (isValidHost(router?.name)) {
          setHideDialogRouter(false);
          return;
        }
        if (host.assetId) {
          setHideDialogHost(false);
          return;
        }
        dispatch(configurationActions.provision(upgrade, router, host, serial, parseInt(selectedConfiguration)));
      } else {
        setHideDialogHost(true);
        setHideDialogRouter(true);
        dispatch(configurationActions.provision(upgrade, router, host, serial, parseInt(selectedConfiguration)));
      }
    };
  };

  const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (lastNumber(router.group) !== item?.key) {
      setHideDialogGroup(false);
    }
    setSelectedItem(item);
  };

  const availableCoachGroups = availableGroups?.filter(record => record.hosttype === "C") || [];
  const availablePylonGroups = availableGroups?.filter(record => record.hosttype === "P") || [];
  const currentGroup = availableGroups?.filter(record => record.ncM_GroupID === lastNumber(router.group)) || [];

  const optionsCoach: IDropdownOption[] =
    [
      { key: 'currentHeader', text: 'Current Config', itemType: DropdownMenuItemType.Header }
    ];
  if (currentGroup?.[0]) {
    optionsCoach.push({
      key: currentGroup[0].ncM_GroupID,
      text: currentGroup[0].groupName
    })
  } else {
    optionsCoach.push({
      key: '0',
      text: 'Unknown'
    })
  }
  optionsCoach.push({
    key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider
  });
  optionsCoach.push({ key: 'otherHeader', text: 'Other Configs', itemType: DropdownMenuItemType.Header },);

  availableCoachGroups.filter(record => record.ncM_GroupID !== lastNumber(router.group)).forEach(availableCoachGroup => {
    optionsCoach.push({ key: availableCoachGroup.ncM_GroupID, text: availableCoachGroup.groupName },);
  })


  const optionsPylon: IDropdownOption[] =
    [
      { key: 'currentHeader', text: 'Current Config', itemType: DropdownMenuItemType.Header }
    ];
  if (currentGroup?.[0]) {
    optionsPylon.push({
      key: currentGroup[0].ncM_GroupID,
      text: currentGroup[0].groupName
    })
  } else {
    optionsPylon.push({
      key: '0',
      text: 'Unknown'
    })
  }
  optionsPylon.push({
    key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider
  });
  optionsPylon.push({ key: 'otherHeader', text: 'Other Configs', itemType: DropdownMenuItemType.Header },);

  availablePylonGroups.filter(record => record.ncM_GroupID !== lastNumber(router.group)).forEach(availablePylonGroup => {
    optionsPylon.push({ key: availablePylonGroup.ncM_GroupID, text: availablePylonGroup.groupName },);
  })

  return (
    <>
      <Card title="Provision Config">
        <div className="section">
          <div className="container__row">
            <div className="container__col-12">
              <Label htmlFor="">Config Template:</Label>
              <Dropdown
                defaultSelectedKey={lastNumber(router.group)}
                label=""
                options={host.hostType === 'C' ? optionsCoach : optionsPylon}
                selectedKey={selectedItem ? selectedItem.key : undefined}
                onChange={onChange}
                id="configDropdownId"
              />
            </div>
          </div>
          <p></p>

          <div className="container__row">
            <div className="container__col-12">
              {configuration.isProvisioning ? (
                <>
                  <Spinner size={SpinnerSize.large} />
                </>
              ) : (
                <DefaultButton
                  primary
                  onClick={doProvision(false, selectedItem?.key)}
                  className="provisionButton"
                >
                  Provision Router to Host
                </DefaultButton>
              )}
            </div>
          </div>
        </div>

        {router && !configuration.isProvisioning ? (
          isCompatible() ? (
            <></>
          ) : (
            <div className="section">
              Unable to provision incompatible device type.
            </div>
          )
        ) : null}

      </Card>

      <Dialog
        hidden={hideDialogGroup}
        onDismiss={() => setHideDialogGroup(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Configuration Change",
        }}
      >
        <MessageBar
          messageBarType={MessageBarType.warning}
        >
          This may initiate a firmware change which could cause a brief delay before its ready. Please allow up to 10 minutes before power cycling.
        </MessageBar>
        <DialogFooter>
          <DefaultButton onClick={() => setHideDialogGroup(true)} primary>
            {" "}
            Acknowledge{" "}
          </DefaultButton>
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDialogRouter}
        onDismiss={() => setHideDialogRouter(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Router already provisioned",
        }}
      >
        This Router is already provisioned to "{router?.name}"".
        <DialogFooter>
          <DefaultButton onClick={doProvision(true, selectedItem?.key)} primary>
            Force Provision
          </DefaultButton>
          <DefaultButton onClick={() => setHideDialogRouter(true)}>
            {" "}
            Cancel{" "}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideDialogHost}
        onDismiss={() => setHideDialogHost(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Host previously provisioned",
        }}
      >
        Barcode(s) {host.assetId} have already been provisioned to this{" "}
        {host.hostType === "C" ? "coach" : "zone"}.
        <DialogFooter>
          <DefaultButton onClick={doProvision(true, selectedItem?.key)} primary>
            Force Provision
          </DefaultButton>
          <DefaultButton onClick={() => setHideDialogHost(true)}>
            {" "}
            Cancel{" "}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ProvisionButton;
