export interface INotification {
  type: "warning" | "info";
  title: string;
  description: string;
  links?: string[];
}

export const notifications: INotification[] = [
  {
    type: "warning",
    title: "M365 Service Interruption",
    description: `We were unable to complete the activation of your M365 products`,
  },
  {
    type: "warning",
    title: "Shipping delay",
    description: `Due to bad weather, the shipment of your Surface Pro 7 has been delayed. New estimated arrival date: 03/26/2021`,
  },
  {
    type: "warning",
    title: "Abnormal shutdown detected",
    description: `We detected that your device DESKTOP-J5H1HJ has been experiencing abnormal shutdown for the last 2 days`,
    links: ["Click here to troubleshoot"],
  },
  {
    type: "info",
    title: "Update your payment method to avoid service interruption",
    description: `The card associated with your account will expire in 30 days`,
    links: ["Click here to update your payment method"],
  },
];

export const debug = process.env.REACT_APP_DEBUG === "1";

export const isValidHost = (string) => {
  return string.indexOf("CP-KCM-") !== -1;
};

export const idAsHost = (hostId) => `CP-KCM-${hostId}`;

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const lastNumber = (url) => {
  if (!url) {
    return 0;
  }
  let urlParts = url.split(/[\/-]+/);
  let last = parseInt(urlParts.pop());
  if (isNaN(last)) {
    last = parseInt(urlParts.pop());
  }
  return last;
};

export const routerType = (router) => {
  switch (lastNumber(router.product)) {
    case 40:
      return "Coach";
    case 35:
      return "Pylon";
    default:
      return "Unknown";
  }
};

export const ipToInt = (ip: string) => {
  var parts = ip.split(".");
  var res = 0;

  res += parseInt(parts[0], 10) << 24;
  res += parseInt(parts[1], 10) << 16;
  res += parseInt(parts[2], 10) << 8;
  res += parseInt(parts[3], 10);

  return res;
};

export const intToIP = (int: number) => {
  var part1 = int & 255;
  var part2 = (int >> 8) & 255;
  var part3 = (int >> 16) & 255;
  var part4 = (int >> 24) & 255;

  return part4 + "." + part3 + "." + part2 + "." + part1;
};

export const oneMoreIP = (inIP: string) => xMoreIP(inIP, 1);
export const xMoreIP = (inIP: string, X: number) => intToIP(ipToInt(inIP) + X);
