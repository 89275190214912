import React, { FormEvent, ReactElement, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Separator, ActionButton, IIconProps, DefaultButton, TextField } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { RootState } from "../../store/reducers";
import { isValidHost, lastNumber } from "../../constants";
import { CoachType, actions as upgradesActions } from "../../ducks/upgrades";
import Card from "../Card";

const Upgrades = () => {

    const [newCoaches, setNewCoaches] = React.useState<{ id: string }[]>([{ id: '' }]);
    const routerData = useSelector(
        (state: RootState) => state.router?.routerDetails
    );
    const routerSource = useSelector((state: RootState) => state.serial?.source);
    const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
    const pendingCoaches = useSelector((state: RootState) => state.upgrades.pendingCoaches);
    const availableGroups = useSelector((state: RootState) => state.groups?.availableGroups);
    const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    const dispatch = useDispatch();

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        setSelectedItem(item);
    };

    const addNewRecord = () => {
        setNewCoaches([...newCoaches, { id: '' }])
    }

    const removeRecord = () => {
        setNewCoaches(newCoaches.slice(0, -1));
    }

    const updateRecord = (recordIndex: number, updateField: string) => (e: FormEvent, newValue?: string) => setNewCoaches(newCoaches.map((currentRecord, index) => (index === recordIndex) ? { ...currentRecord, [updateField]: newValue } : currentRecord));

    const completeForm = () => {
        if (selectedItem) {
            dispatch(upgradesActions.moveCoaches(newCoaches, selectedItem.key.toString()));
            setNewCoaches([{ id: '' }]);
        }
    }

    const clearUpgrades = () => {
        dispatch(upgradesActions.clearUpgrades());
    }

    const availableCoachGroups = availableGroups?.filter(record => record.hosttype === "C") || [];
    const availablePylonGroups = availableGroups?.filter(record => record.hosttype === "P") || [];
    const groupOptions: IDropdownOption[] =
        [
            { key: 'coachHeader', text: 'Coach Configs', itemType: DropdownMenuItemType.Header }
        ];
    availableCoachGroups.forEach(availableGroupRecord => {
        groupOptions.push({
            key: availableGroupRecord.ncM_GroupID,
            text: availableGroupRecord.groupName
        })
    });
    groupOptions.push({
        key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider
    });
    groupOptions.push({ key: 'pylonHeader', text: 'Pylon Configs', itemType: DropdownMenuItemType.Header });
    availablePylonGroups.forEach(availableGroupRecord => {
        groupOptions.push({
            key: availableGroupRecord.ncM_GroupID,
            text: availableGroupRecord.groupName
        })
    });

    if (pendingCoaches.length > 0) {

        return (
            <Card title="Upgrading Hosts" className="upgrades">
                <div className="section">
                    {
                        pendingCoaches.map(pendingCoach => (
                            <div key={pendingCoach.id}>
                                {pendingCoach.failed && !pendingCoach.complete && (
                                    <p style={{ color: '#a00000' }}>
                                        Coach: #{pendingCoach.id} - FAILED
                                    </p>
                                )}
                                {!pendingCoach.failed && pendingCoach.complete && (
                                    <p style={{ color: '#00a000' }}>
                                        Coach: #{pendingCoach.id} - CONFIGURED
                                    </p>
                                )}
                                {!pendingCoach.failed && !pendingCoach.complete && (
                                    <p style={{ color: '#a0a000' }}>
                                        Coach: #{pendingCoach.id} - RUNNING...
                                    </p>
                                )}
                                <p>
                                    Configuration Status: {pendingCoach.status}
                                </p>
                                <p>
                                    Router Status:
                                    <>
                                        {pendingCoach.serialStatus.configStatus === 'synched' ? (<span className="tag" style={{ backgroundColor: '#00a000' }}>Synched</span>) : null}
                                    </>
                                    <>
                                        {pendingCoach.serialStatus.configStatus === 'pending' ? (<span className="tag" style={{ backgroundColor: '#a0a000' }}>Pending</span>) : null}
                                    </>
                                    <>
                                        {pendingCoach.serialStatus.configStatus === 'suspended' ? (<span className="tag" style={{ backgroundColor: '#a00000' }}>Suspended</span>) : null}
                                    </>
                                    <>
                                        {pendingCoach.serialStatus.state === 'online' ? (<span className="tag" style={{ backgroundColor: '#0000a0' }}>Online</span>) : null}
                                    </>
                                    <>
                                        {pendingCoach.serialStatus.state === 'offline' ? (<span className="tag" style={{ backgroundColor: '#808080' }}>Offline</span>) : null}
                                    </>
                                </p>

                                <Separator />
                            </div>
                        ))
                    }
                    <div>
                        <DefaultButton onClick={clearUpgrades} primary>Clear Upgrades</DefaultButton>
                    </div>
                </div>
            </Card>
        );
    } else {
        return (
            <Card title="Upgrade Hosts">
                <div className="section">
                    <div className="container__row">
                        <div className="container__col-12">
                            <Label htmlFor="">Config Template:</Label>
                            <Dropdown
                                label=""
                                options={groupOptions}
                                selectedKey={selectedItem ? selectedItem.key : undefined}
                                onChange={onChange}
                                id="configDropdownId"
                            />
                        </div>
                    </div>
                </div>
                <>
                    {newCoaches.map((newCoachesRecord, i) => (
                        <div className="upgradesRecord" key={i}>
                            <Separator>Host #{i + 1}</Separator>
                            <TextField label="Host ID" required onChange={updateRecord(i, 'id')} />
                        </div>
                    ))}
                </>
                <Separator />
                <div>
                    <DefaultButton onClick={() => addNewRecord()}>+ More</DefaultButton> {' '}
                    {(newCoaches.length > 1) && (
                        <DefaultButton onClick={() => removeRecord()}>- Less</DefaultButton>
                    )}
                    <DefaultButton onClick={completeForm} primary className="maintenanceFlushRight">Upgrade Hosts</DefaultButton>
                </div>

            </Card>
        );
    }
};

export default Upgrades;
