import {
  all,
  takeEvery,
  put,
  call,
  delay,
  select,
  fork,
} from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { debug } from "../constants";
import { NAPIPrefix, APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";
import { actions as notificationActions } from "./notifications";

type State =
  | {
    routerId?: string;
    hostId?: string;
    devices: any[];
    devicesHealth: any[];
    speed_tests: any[];
    isLoading: boolean;
    isPinging: boolean | string;
    status: any;
  }
  | undefined;

export const diagnosticsNetDevicesUrl = `${APIPrefix}net_devices/`;
export const diagnosticsNetDeviceHealthUrl = `${APIPrefix}net_devices/health/`;
export const diagnosticsPingUrl = `${APIPrefix}ncos_router_api/ping_start/`;
export const diagnosticsPingStatusUrl = `${APIPrefix}ncos_router_api/ping_status/`;
export const diagnosticsPingResultsUrl = `${APIPrefix}ncos_router_api/ping_result/`;
export const diagnosticsStatus = `${APIPrefix}ncos_router_api/`;

// Todo - alerts & speed tests

// Initial State
const initialState: State = {
  hostId: "",
  routerId: "",
  devices: [],
  devicesHealth: [],
  speed_tests: [],
  isLoading: false,
  isPinging: false,
  status: {},
};

// Debug State
const debugState: State = {
  routerId: "1259235",
  hostId: "00450",
  isPinging: "",
  devices: [
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: null,
      connection_state: null,
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: null,
      iccid: null,
      id: "36732825",
      imei: null,
      imsi: null,
      ipv4_address: null,
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: null,
      manufacturer: null,
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: null,
      modem_fw: null,
      mtu: null,
      nai: null,
      name: "ethernet-wan",
      netmask: null,
      pin_status: null,
      port: "",
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/36732825/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "Ethernet",
      ssid: null,
      summary: null,
      txchannel: null,
      type: "ethernet",
      uid: "wan",
      updated_at: "2022-07-26T01:54:26.798263+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: null,
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: "Verizon Wireless",
      carrier_id: "Verizon",
      channel: null,
      connection_state: "connected",
      dns0: "10.5.3.146",
      dns1: "146.129.56.39",
      esn: null,
      gateway: "10.211.2.178",
      gsn: "357188080581152",
      homecarrid: "Verizon",
      hostname: "CP-KCM-2561",
      iccid: "89148000004738207490",
      id: "36732826",
      imei: "357188080581152",
      imsi: "311480471025874",
      ipv4_address: "10.211.2.177",
      is_asset: true,
      is_gps_supported: true,
      is_upgrade_available: false,
      is_upgrade_supported: true,
      ltebandwidth: "20 MHz",
      mac: null,
      manufacturer: "Cradlepoint Inc.",
      mdn: "+14257579757",
      meid: "35718808058115",
      mfg_model: "LM940-CP",
      mfg_product: "Internal 600M (SIM1)",
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: "Internal 600M (SIM1)",
      modem_fw: "Verizon Wireless",
      mtu: 1428,
      nai: null,
      name: "mdm-107ab21bd",
      netmask: "255.255.255.252",
      pin_status: "READY",
      port: "int1",
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/36732826/",
      rfband: "66",
      rfchannel: "66586",
      roam: "1",
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: "357188080581152",
      service_type: "LTE",
      ssid: null,
      summary: "connected",
      txchannel: "132122",
      type: "mdm",
      uid: "107ab21bd",
      updated_at: "2022-07-26T15:17:42.808123+00:00",
      uptime: 64814.9842278319,
      ver_pkg: "24.01.521_Verizon,2013",
      version: "24.01.521",
      wimax_realm: "sprintpcs.com",
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: "Other (FirstNet)",
      carrier_id: null,
      channel: null,
      connection_state: "unplugged",
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: "357188080581152",
      homecarrid: "FirstNet",
      hostname: "IBR1700-44b",
      iccid: "89011003300002560508",
      id: "36732827",
      imei: "357188080581152",
      imsi: "313100000256050",
      ipv4_address: null,
      is_asset: true,
      is_gps_supported: true,
      is_upgrade_available: false,
      is_upgrade_supported: true,
      ltebandwidth: null,
      mac: null,
      manufacturer: "Cradlepoint Inc.",
      mdn: null,
      meid: "35718808058115",
      mfg_model: "LM940-CP",
      mfg_product: "Internal 600M (SIM2)",
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: "Internal 600M (SIM2)",
      modem_fw: "AT&T",
      mtu: 1428,
      nai: null,
      name: "mdm-107d8e874",
      netmask: null,
      pin_status: "READY",
      port: "int1",
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/36732827/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: "357188080581152",
      service_type: "Not Available",
      ssid: null,
      summary: "unplugged",
      txchannel: null,
      type: "mdm",
      uid: "107d8e874",
      updated_at: "2022-07-26T01:54:26.297048+00:00",
      uptime: null,
      ver_pkg: "24.01.543_ATT,4018",
      version: "24.01.543",
      wimax_realm: "sprintpcs.com",
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: "Verizon Wireless",
      carrier_id: "Not registered",
      channel: null,
      connection_state: "disconnected",
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: "355689090108532",
      homecarrid: "Verizon",
      hostname: "CP-KCM-2561",
      iccid: "89148000004738214439",
      id: "36733023",
      imei: "355689090108532",
      imsi: "311480470997935",
      ipv4_address: null,
      is_asset: true,
      is_gps_supported: true,
      is_upgrade_available: false,
      is_upgrade_supported: true,
      ltebandwidth: "15 MHz",
      mac: null,
      manufacturer: "Cradlepoint Inc.",
      mdn: null,
      meid: "35568909010853",
      mfg_model: "LM960-CP",
      mfg_product: "MC400 1200M (SIM1)",
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: "MC400 1200M (SIM1)",
      modem_fw: "AT&T",
      mtu: 1428,
      nai: null,
      name: "mdm-b708f3b1",
      netmask: null,
      pin_status: "READY",
      port: "modem1",
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/36733023/",
      rfband: "2",
      rfchannel: "1125",
      roam: "0",
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: "355689090108532",
      service_type: "Not Available",
      ssid: null,
      summary: "configure error",
      txchannel: "19125",
      type: "mdm",
      uid: "b708f3b1",
      updated_at: "2022-07-26T01:54:26.804928+00:00",
      uptime: null,
      ver_pkg: "32.00.041_ATT,4013",
      version: "32.00.041",
      wimax_realm: "sprintpcs.com",
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: "Other (FirstNet)",
      carrier_id: null,
      channel: null,
      connection_state: "disconnected",
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: "355689090108532",
      homecarrid: "FirstNet",
      hostname: "CP-KCM-2561",
      iccid: "89011003300002560490",
      id: "36733024",
      imei: "355689090108532",
      imsi: "313100000256049",
      ipv4_address: null,
      is_asset: true,
      is_gps_supported: true,
      is_upgrade_available: false,
      is_upgrade_supported: true,
      ltebandwidth: null,
      mac: null,
      manufacturer: "Cradlepoint Inc.",
      mdn: null,
      meid: "35568909010853",
      mfg_model: "LM960-CP",
      mfg_product: "MC400 1200M (SIM2)",
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: "MC400 1200M (SIM2)",
      modem_fw: "AT&T",
      mtu: 1428,
      nai: null,
      name: "mdm-b736bae8",
      netmask: null,
      pin_status: "READY",
      port: "modem1",
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/36733024/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: "355689090108532",
      service_type: "Not Available",
      ssid: null,
      summary: "disabled",
      txchannel: null,
      type: "mdm",
      uid: "b736bae8",
      updated_at: "2022-07-26T01:54:26.812722+00:00",
      uptime: null,
      ver_pkg: "32.00.041_ATT,4013",
      version: "32.00.041",
      wimax_realm: "sprintpcs.com",
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: 149,
      connection_state: null,
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: null,
      iccid: null,
      id: "97290496",
      imei: null,
      imsi: null,
      ipv4_address: null,
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: null,
      manufacturer: null,
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "lan",
      model: null,
      modem_fw: null,
      mtu: null,
      nai: null,
      name: "wlan-wireless0_1",
      netmask: null,
      pin_status: null,
      port: null,
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/97290496/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "WiFi",
      ssid: "IBR1700-44b-5g",
      summary: null,
      txchannel: null,
      type: "wlan",
      uid: "wireless0_1",
      updated_at: "2022-07-26T01:54:26.760757+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: null,
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: null,
      connection_state: null,
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: "VIX",
      iccid: null,
      id: "97290497",
      imei: null,
      imsi: null,
      ipv4_address: "10.216.22.97",
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: null,
      manufacturer: null,
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "lan",
      model: null,
      modem_fw: null,
      mtu: null,
      nai: null,
      name: "ethernet-VIX",
      netmask: "255.255.255.224",
      pin_status: null,
      port: null,
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/97290497/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "Ethernet",
      ssid: null,
      summary: null,
      txchannel: null,
      type: "ethernet",
      uid: "VIX",
      updated_at: "2022-07-26T01:54:26.765811+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: null,
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: null,
      connection_state: null,
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: "OBS",
      iccid: null,
      id: "97290498",
      imei: null,
      imsi: null,
      ipv4_address: "10.10.255.1",
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: null,
      manufacturer: null,
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "lan",
      model: null,
      modem_fw: null,
      mtu: null,
      nai: null,
      name: "ethernet-OBS",
      netmask: "255.255.255.0",
      pin_status: null,
      port: null,
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/97290498/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "Ethernet",
      ssid: null,
      summary: null,
      txchannel: null,
      type: "ethernet",
      uid: "OBS",
      updated_at: "2022-07-26T01:54:26.771054+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: null,
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: null,
      connection_state: null,
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: null,
      iccid: null,
      id: "97290499",
      imei: null,
      imsi: null,
      ipv4_address: null,
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: null,
      manufacturer: null,
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "lan",
      model: null,
      modem_fw: null,
      mtu: null,
      nai: null,
      name: "ethernet-VMS",
      netmask: null,
      pin_status: null,
      port: null,
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/97290499/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "Ethernet",
      ssid: null,
      summary: null,
      txchannel: null,
      type: "ethernet",
      uid: "VMS",
      updated_at: "2022-07-26T01:54:26.776117+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: null,
    },
    {
      account: "https://www.cradlepointecm.com/api/v2/accounts/101014/",
      bsid: null,
      carrier: null,
      carrier_id: null,
      channel: 0,
      connection_state: "disconnected",
      dns0: null,
      dns1: null,
      esn: null,
      gateway: null,
      gsn: null,
      homecarrid: null,
      hostname: "CP-KCM-2561",
      iccid: null,
      id: "97290500",
      imei: null,
      imsi: null,
      ipv4_address: null,
      is_asset: false,
      is_gps_supported: false,
      is_upgrade_available: false,
      is_upgrade_supported: false,
      ltebandwidth: null,
      mac: "00:30:44:32:A4:4E",
      manufacturer: "Cradlepoint",
      mdn: null,
      meid: null,
      mfg_model: null,
      mfg_product: null,
      mn_ha_spi: null,
      mn_ha_ss: null,
      mode: "wan",
      model: null,
      modem_fw: null,
      mtu: 1500,
      nai: null,
      name: "wwan-K9C8SC67BR5:5G-3",
      netmask: null,
      pin_status: null,
      port: null,
      prlv: null,
      profile: null,
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_devices/97290500/",
      rfband: null,
      rfchannel: null,
      roam: null,
      router: "https://www.cradlepointecm.com/api/v2/routers/1259235/",
      rxchannel: null,
      serial: null,
      service_type: "WiFi",
      ssid: "K9C8SC67BR5",
      summary: "Network not found",
      txchannel: null,
      type: "wwan",
      uid: "K9C8SC67BR5:5G-3",
      updated_at: "2022-07-26T01:54:26.790261+00:00",
      uptime: null,
      ver_pkg: null,
      version: null,
      wimax_realm: "sprintpcs.com",
    },
  ],
  devicesHealth: [
    {
      cellular_health_category: "fair",
      cellular_health_score: 30,
      id: "53083962",
      net_device: "https://www.cradlepointecm.com/api/v2/net_devices/36732826/",
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_device_health/53083962/",
    },
    {
      cellular_health_category: "poor",
      cellular_health_score: 0,
      id: "53490514",
      net_device: "https://www.cradlepointecm.com/api/v2/net_devices/36733024/",
      resource_url:
        "https://www.cradlepointecm.com/api/v2/net_device_health/53490514/",
    },
  ],
  speed_tests: [],
  status: {
    fetching: false
  },
  isLoading: false,
};

// Actions
const actionTypes = {
  diagDevices: "DIAGNOSTICS_GET_DEVICES",
  diagDevicesSuccess: "DIAGNOSTICS_GET_DEVICES_SUCCESS",
  diagDevicesFailure: "DIAGNOSTICS_GET_DEVICES_FAILURE",

  diagDeviceHealth: "DIAGNOSTICS_GET_DEVICE_HEALTH",
  diagDeviceHealthSuccess: "DIAGNOSTICS_GET_DEVICE_HEALTH_SUCCESS",
  diagDeviceHealthFailure: "DIAGNOSTICS_GET_DEVICE_HEALTH_FAILURE",

  clearPing: "DIAGNOSTICS_CLEAR_PING",
  startPing: "DIAGNOSTICS_START_PING",
  startPingSuccess: "DIAGNOSTICS_START_PING_SUCCESS",
  startPingFailure: "DIAGNOSTICS_START_PING_FAILURE",
  checkPing: "DIAGNOSTICS_CHECK_PING",
  resultsPing: "DIAGNOSTICS_RESULTS_PING",

  diagStatusGet: "DIAGNOSTICS_STATUS_GET",
  diagStatusResult: "DIAGNOSTICS_STATUS_RESULT"
};

const actions = {
  getDevices: (routerId: string) =>
    action(actionTypes.diagDevices, { routerId }),
  getDevicesSuccess: (devices: any[]) =>
    action(actionTypes.diagDevicesSuccess, { devices }),
  getDevicesFailure: () => action(actionTypes.diagDevicesFailure),

  getDeviceHealth: (deviceId: string) =>
    action(actionTypes.diagDeviceHealth, { deviceId }),
  getDeviceHealthSuccess: (deviceId: string, health: any) =>
    action(actionTypes.diagDeviceHealthSuccess, { deviceId, health }),
  getDeviceHealthFailure: (deviceId: string) =>
    action(actionTypes.diagDeviceHealthFailure, { deviceId }),
  clearPing: () => action(actionTypes.clearPing),
  startPing: (hostId: string, hosts: string[]) =>
    action(actionTypes.startPing, { hostId, hosts }),
  startPingSuccess: (response: any) =>
    action(actionTypes.startPingSuccess, { response }),
  startPingFailure: (response: any) =>
    action(actionTypes.startPingFailure, { response }),
  pingResults: (host: string, results: any) => action(actionTypes.resultsPing, { host, results }),
  statusGet: (host: string) => action(actionTypes.diagStatusGet, { host }),
  statusResults: (host: string, results: any) => action(actionTypes.diagStatusResult, { host, results }),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.diagDevices]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actionTypes.diagDevicesSuccess]: (state, action) => ({
    ...state,
    devices: action.payload.devices,
    isLoading: false,
  }),
  [actionTypes.diagDeviceHealthSuccess]: (state, action) => ({
    ...state,
    devicesHealth: [...state.devicesHealth, action.payload.health],
  }),
  [actionTypes.startPingSuccess]: (state, action) => ({
    ...state,
    isPinging: action?.hostId,
  }),
  [actionTypes.startPingFailure]: (state, action) => ({
    ...state,
    isPinging: false,
    speed_tests: [...state.speed_tests, { success: false, reason: 'Unable to initiate ping' }],
  }),
  [actionTypes.clearPing]: (state, action) => ({
    ...state,
    speed_tests: [],
    isPinging: false
  }),
  [actionTypes.resultsPing]: (state, action) => {
    let success = false;
    let reason = '';

    if ((action.payload.results?.data || '').includes('time=')) {
      success = true;
      reason = 'Success'; // Todo, add ping time
    } else {
      success = false;
      reason = 'Timeout';
    }

    const pingRecord = {
      success,
      reason
    }

    return ({
      ...state,
      isPinging: false,
      speed_tests: [...state.speed_tests, pingRecord],
    })
  },
  [actionTypes.diagStatusGet]: (state, action) => {
    return ({
      ...state,
      status: {
        fetching: true
      }
    })
  },
  [actionTypes.diagStatusResult]: (state, action) => {
    return ({
      ...state,
      status: {
        fetching: false,
        ...action.payload.results
      }
    })
  }
});

// Saga
const saga = function* (): SagaIterator {
  yield all([
    takeEvery(actionTypes.diagDevices, waitDiagDevices as any),
    takeEvery(actionTypes.diagDeviceHealth, waitDiagDeviceHealth as any),
    takeEvery(actionTypes.startPing, waitDiagStartPing as any),
    takeEvery(actionTypes.diagStatusGet, waitDiagStatusGet as any),
  ]);
};


function* waitDiagStartPing(action: {
  type: typeof actions.startPing;
  payload: { hostId: string; hosts: string[] };
}) {
  const accessToken = yield select((state) => state.auth.accessToken);
  yield put(actions.clearPing());
  for (const host of action.payload.hosts) {
    try {
      const { data } = yield call(
        axios.put,
        diagnosticsPingUrl +
        `${action.payload.hostId}.kc.kingcounty.lcl?host=${host}`,
        {},
        {
          headers: apiHeaders(accessToken),
        }
      );
      if (data.success) {
        yield put(actions.startPingSuccess({ ...action.payload }));
      }
    } catch (e) {
      yield put(actions.startPingFailure({ ...action.payload }));
    }
    // Wait for results to show anything except "running"
    let results = 'none';
    while (results === 'none') {
      yield delay(2000);
      const { data: statusData } = yield call(
        axios.get,
        diagnosticsPingStatusUrl +
        `${action.payload.hostId}.kc.kingcounty.lcl`,
        {
          headers: apiHeaders(accessToken),
        }
      );
      if (statusData.results !== 'running') {
        results = 'done';
      }
    }
    // Get results of ping job
    const { data: resultsData } = yield call(
      axios.get,
      diagnosticsPingResultsUrl +
      `${action.payload.hostId}.kc.kingcounty.lcl`,
      {
        headers: apiHeaders(accessToken),
      }
    );
    yield put(actions.pingResults(host, resultsData));
  }
}

function* waitDiagDevices(action: {
  type: typeof actions.getDevices;
  payload: { routerId: string };
}) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    console.log(
      "getting device information from ",
      diagnosticsNetDevicesUrl + action.payload.routerId
    );
    const { data } = yield call(
      axios.get,
      diagnosticsNetDevicesUrl + action.payload.routerId,
      {
        headers: apiHeaders(accessToken),
      }
    );

    if (!data) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Error running diagnostics on router!`,
        })
      );
    } else {
      yield put(actions.getDevicesSuccess(data));
    }
  } catch (e: any) {
    if (e?.status === 404) {
      notificationActions.addNotification({
        type: "error",
        title: `Unable to run diagnostics on router`,
      });
    } else {
      notificationActions.addNotification({
        type: "error",
        title: `Unknown error occured: ${e.message}`,
      });
    }
  }
}

function* waitDiagDeviceHealth(action: {
  type: typeof actions.getDeviceHealth;
  payload: { deviceId: string };
}) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const deviceId = yield select((state) => action.payload.deviceId);
    const { data } = yield call(
      axios.get,
      diagnosticsNetDeviceHealthUrl + deviceId,
      {
        headers: apiHeaders(accessToken),
      }
    );

    if (!data) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Error querying device health on router!`,
        })
      );
    } else {
      yield put(actions.getDeviceHealthSuccess(deviceId, data[0]));
    }
  } catch (e: any) {
    if (e?.status === 404) {
      notificationActions.addNotification({
        type: "error",
        title: `Unable to run diagnostics on router`,
      });
    } else {
      notificationActions.addNotification({
        type: "error",
        title: `Unknown error occured: ${e.message}`,
      });
    }
  }
}

function* waitDiagStatusGet(action: {
  type: typeof actions.statusGet;
  payload: { host: string };
}) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const { data } = yield call(
      axios.get,
      diagnosticsStatus + action.payload.host + `?param=state`,
      {
        headers: apiHeaders(accessToken),
      }
    );
    yield put(actions.statusResults(action.payload.host, data.data))
  } catch (e) {
    // Load failed, just return empty record
    yield put(actions.statusResults(action.payload.host, {}))
  }
}

export { reducer, actions, saga };
