import React from "react";
import { Provider } from "react-redux";
import store from "../../store/store";
import Frame from "../../components/Frame";
import Card from "../../components/Card";
import {
  Text,
  FontWeights,
  ITextStyles,
  CompoundButton,
  FontIcon,
} from "@fluentui/react";
import { appInsights, reactPlugin } from "../../configs/appInsights";

export const ErrorPage = (props: any) => {
  return (
    <Provider store={store}>
      <Frame>
        <Card title="Error" iconName="Error">
          <p>
            <strong>An error occured while performing the last request.</strong>
          </p>
          <p>The problem has been logged and sent to the developers.</p>
          <CompoundButton
            primary
            className="reloadButton"
            text="Reload Application"
            secondaryText="Click to restart the application"
            disabled={false}
            checked={false}
            iconProps={{ iconName: "ReturnToSession" }}
            onClick={() => window.location.reload()}
          />
        </Card>
      </Frame>
    </Provider>
  );
};

export default ErrorPage;
