import { createReducer, action } from "typesafe-actions";
import { all, takeEvery, put, call, select, delay } from "redux-saga/effects";
import { NAPIPrefix, APIPrefix, apiHeaders } from "../configs/apiConfig";
import axios from "axios";
import { debug, lastNumber, oneMoreIP } from "../constants";
import { actions as notificationActions } from "./notifications";
import { actions as routerActions } from "./router";
import { actions as hostActions } from "./hosts";
import { actions as serialActions } from "./serial";
import { actions as configurationActions } from "./configuration";
import { SagaIterator } from "redux-saga";
import { serialSearchURL } from "./serial";
import { hostSearchURL } from "./hosts";

export const upgradesURL = `${APIPrefix}`;

export type CoachType = {
    id: string;
    status: string;
    serialStatus: {
        assetId: string,
        configStatus: string,
        pendingUpgrade: boolean,
        state: string,
    };
    failed: boolean;
    complete: boolean;
}

export type UpgradeRecord = {
    hostId: string;
    router: any,
    host: any,
    serial: any
}

type State = {
    targetNCMGroupId?: number;
    targetGroupHosts: number[];
    pendingCoaches: CoachType[];
    upgradeRecords: UpgradeRecord[];
};

// Initial State
const initialState: State = {
    targetNCMGroupId: undefined,
    targetGroupHosts: [],
    pendingCoaches: [],
    upgradeRecords: []
};

// Debug State
const debugState: State = {
    targetNCMGroupId: 395429,
    targetGroupHosts: [
        1, 2, 3
    ],
    pendingCoaches: [],
    upgradeRecords: []
};

// Actions
const actionTypes = {
    loadAll: 'UPGRADES_LOAD_ALL',
    loadCoach: 'UPGRADES_LOAD_COACH',
    moveCoaches: 'UPGRADES_MOVE_COACH',
    updateStatus: 'UPGRADES_UPDATE_STATUS',
    updateSerialStatus: 'UPGRADES_UPDATE_SERIAL_STATUS',
    clearUpgrades: 'UPGRADES_CLEAR',
    moveCoachesSuccess: 'UPGRADES_MOVE_COACH_SUCCESS',
    moveCoachesFailure: 'UPGRADES_MOVE_COACH_FAILURE',
};

const actions = {
    loadAll: (coaches: { id: string }[], targetNCMGroupId: string) =>
        action(actionTypes.loadAll, { coaches, targetNCMGroupId }),
    loadCoach: (coach: { id: string }, targetNCMGroupId: string) =>
        action(actionTypes.loadCoach, { coach, targetNCMGroupId }),
    moveCoaches: (coaches: { id: string }[], targetNCMGroupId: string) =>
        action(actionTypes.moveCoaches, { coaches, targetNCMGroupId }),
    clearUpgrades: () => action(actionTypes.clearUpgrades),
    moveCoachSuccess: () => action(actionTypes.moveCoachesSuccess),
    moveCoachFailure: () => action(actionTypes.moveCoachesFailure),
    updateStatus: (hostId: string, status: string, failed?: boolean, complete?: boolean) =>
        action(actionTypes.updateStatus, { hostId, status, failed, complete }),
    updateSerialStatus: (hostId: string, serialData: any) => action(actionTypes.updateSerialStatus, serialData)
};

const reducer = createReducer(debug ? debugState : initialState, {
    [actionTypes.clearUpgrades]: (state, action) => ({
        ...state,
        pendingCoaches: []
    }),
    [actionTypes.moveCoaches]: (state, action) => ({
        ...state,
        pendingCoaches: action.payload.coaches.map(coach => {
            return {
                id: coach.id,
                status: 'Starting',
                failed: false,
                complete: false,
                serialStatus: {
                    assetId: '',
                    state: '',
                    pendingUpdate: false,
                    configStatus: '',
                }
            }
        })
    }),
    [actionTypes.updateStatus]: (state, action) => ({
        ...state,
        pendingCoaches: (state.pendingCoaches).map(pendingCoach => {
            if (pendingCoach.id === action.payload.hostId) {
                return {
                    id: pendingCoach.id,
                    status: action.payload.status,
                    failed: action.payload.failed ?? false,
                    complete: action.payload.complete ?? false,
                    serialStatus: pendingCoach.serialStatus
                }
            } else {
                return pendingCoach;
            }
        })
    }),
    [actionTypes.updateSerialStatus]: (state, action) => ({
        ...state,
        pendingCoaches: (state.pendingCoaches.map(pendingCoach => {
            if (pendingCoach.id === lastNumber(action.payload.name).toString()) {
                return {
                    ...pendingCoach,
                    serialStatus: {
                        assetId: action.payload.asset_id,
                        configStatus: action.payload.config_status,
                        pendingUpgrade: action.payload.pending_upgrade,
                        state: action.payload.state,
                    }
                }
            } else {
                return pendingCoach;
            }
        }))
    })
});

// Saga
const saga = function* (): SagaIterator {
    yield all([
        call(refreshLoop),
        takeEvery(actionTypes.moveCoaches, waitLoadAll as any),
        takeEvery(actionTypes.loadCoach, waitLoadCoach as any)
    ]);
};

function* refreshLoop() {
    while (true) {
        const pendingCoaches = yield select((state) => state.upgrades.pendingCoaches);
        if ((pendingCoaches || []).length > 0) {
            const accessToken = yield select((state) => state.auth.accessToken);
            for (const pendingCoach of pendingCoaches) {
                const { data: hostData } = yield call(
                    axios.get,
                    hostSearchURL + pendingCoach.id,
                    {
                        headers: apiHeaders(accessToken),
                    }
                );
                let { data: serialData } = yield call(
                    axios.get,
                    serialSearchURL + hostData?.assetId,
                    {
                        headers: apiHeaders(accessToken),
                    }
                );
                yield put(actions.updateSerialStatus(pendingCoach.id, serialData[0]));
            }
        }
        yield delay(60000); // 60 sec
    }
}

function* waitLoadCoach(action: {
    type: typeof actions.loadAll,
    payload: { coach: { id: string }, targetNCMGroupId: string }
}) {
    try {
        if (action.payload.coach.id === '') {
            yield put(actions.updateStatus(action.payload.coach.id, 'Invalid Host Id', true));
            return;
        }

        const accessToken = yield select((state) => state.auth.accessToken);
        yield put(actions.updateStatus(action.payload.coach.id, 'Loading host information'));
        const { data: hostData } = yield call(
            axios.get,
            hostSearchURL + action.payload.coach.id,
            {
                headers: apiHeaders(accessToken),
            }
        );
        if (!hostData) {
            // Throw error return
            yield put(actions.updateStatus(action.payload.coach.id, 'Unable to load host information', true));
            return;
        }
        yield put(actions.updateStatus(action.payload.coach.id, 'Loading router information'));

        // Iterate through each coach loading Router,Host, and Serial
        let { data: serialData } = yield call(
            axios.get,
            serialSearchURL + hostData?.assetId,
            {
                headers: apiHeaders(accessToken),
            }
        );
        if (!serialData) {
            // Throw error return
            yield put(actions.updateStatus(action.payload.coach.id, 'Unable to load router information', true));
            return;
        }
        yield put(actions.updateSerialStatus(action.payload.coach.id, serialData[0]));

        const serial = {
            serial: hostData?.assetId
        };
        const router = serialData[0];

        if (lastNumber(router.group) === parseInt(action.payload.targetNCMGroupId, 10)) { // No need to upgrade to a group the Host already exists in
            yield put(actions.updateStatus(action.payload.coach.id, 'Host already in group', false, true));
            return;
        }

        yield put(actions.updateStatus(action.payload.coach.id, 'Configuring...'));
        yield put(configurationActions.provision(true, { routerDetails: router }, hostData, serial, parseInt(action.payload.targetNCMGroupId, 10), true));
    } catch (e) {
        console.log('upgrade error', e);
        yield put(actions.updateStatus(action.payload.coach.id, `An error occured: ${e}`, true));
    }
}

function* waitLoadAll(action: {
    type: typeof actions.loadAll,
    payload: { coaches: { id: string }[], targetNCMGroupId: string }
}) {
    for (const coach of action.payload?.coaches) {
        yield put(actions.loadCoach({ id: coach.id }, action.payload.targetNCMGroupId));
    }
}

export { reducer, actions, saga };
