import React from "react";

export const responsiveRow = (key, value) => (
  <>
    <div className="container--fluid hidden-sm-up listRow">
      <div className="container__col-12 listKey">{key} :</div>
    </div>
    <div className="container--fluid hidden-sm-up listRow">
      <div className="container__col-offset-1 container__col-10 listValue">
        {value}
      </div>
    </div>

    <div className="container--fluid hidden-sm-down listRow">
      <div className="container__col-sm-4 listKey">{key} :</div>
      <div className="container__col-sm-8 listValue">{value}</div>
    </div>
  </>
);
