import { all, takeEvery, put, call, select, delay } from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { debug } from "../constants";
import { APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";
import { actions as routerActions } from "./router";
import { actions as notificationActions } from "./notifications";

type State =
  | {
    hostId: string;
    hostRecord: any;
    lastError: string;
    source: string;
    isLoading: boolean;
  }
  | undefined;

export const hostSearchURL = `${APIPrefix}hosts/`;

// Initial State
const initialState: State = {
  hostId: "",
  hostRecord: {},
  lastError: "",
  source: "",
  isLoading: false,
};

// Debug State
const debugState: State = {
  hostId: "2651",
  hostRecord: {
    hostID: "2651",
    hostType: "C",
    lanIP: "10.215.28.129",
    lanSubnet: "10.215.28.128",
    natIP: "10.213.2.91",
    vmsNet: "",
    assetId: "1394",
    latitude: null,
    longitude: null,
  },
  source: "diagnostics",
  lastError: "",
  isLoading: false,
};

// Actions
const actionTypes = {
  searchHost: "HOST_SEARCH",
  searchHostSuccess: "HOST_SEARCH_SUCCESS",
  searchHostFailure: "HOST_SEARCH_FAILURE",
  hostRefresh: "HOST_REFRESH",
  clearHost: "HOST_CLEAR",
};

const actions = {
  searchHost: (host: string, source: string) =>
    action(actionTypes.searchHost, { host, source }),
  searchHostSuccess: (host: string, hostRecord: any) =>
    action(actionTypes.searchHostSuccess, { host, hostRecord }),
  searchHostFailure: (error: string) =>
    action(actionTypes.searchHostFailure, { error }),
  hostRefresh: (host: string, hostRecord: any) =>
    action(actionTypes.hostRefresh, { host, hostRecord }),
  clearHost: () => action(actionTypes.clearHost),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.searchHost]: (state, action) => ({
    ...state,
    hostId: action.payload.host,
    lastError: "",
    source: action.payload.source,
    isLoading: true,
  }),
  [actionTypes.searchHostSuccess]: (state, action) => ({
    ...state,
    hostId: action.payload.host,
    hostRecord: action.payload.hostRecord,
    isLoading: false,
    lastError: "",
  }),
  [actionTypes.hostRefresh]: (state, action) => ({
    ...state,
    hostId: action.payload.host,
    hostRecord: action.payload.hostRecord,
    isLoading: false,
    lastError: "",
  }),
  [actionTypes.searchHostSuccess]: (state, action) => ({
    ...state,
    hostId: action.payload.host,
    hostRecord: action.payload.hostRecord,
    isLoading: false,
    lastError: "",
  }),
  [actionTypes.searchHostFailure]: (state, action) => ({
    ...state,
    hostId: "",
    isLoading: false,
    lastError: action.payload.error,
  }),
  [actionTypes.clearHost]: (state, action) => ({
    ...state,
    hostId: "",
    hostRecord: {},
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([
    takeEvery(actionTypes.searchHost, waitSearchHost as any),
    call(refreshLoop),
  ]);
};

function* refreshLoop() {
  while (true) {
    const hostRecord = yield select((state) => state.hosts);
    if (hostRecord.hostId) {
      const accessToken = yield select((state) => state.auth.accessToken);
      const { data } = yield call(
        axios.get,
        hostSearchURL + hostRecord.hostId,
        {
          headers: apiHeaders(accessToken),
        }
      );
      if (data) {
        yield put(actions.hostRefresh(hostRecord.hostId, data));
      }
    }
    yield delay(5000);
  }
}

function* waitSearchHost(action: {
  type: typeof actions.searchHost;
  payload: { host: string };
}) {
  try {
    const router = yield select((state) => state.router.routerDetails);
    const accessToken = yield select((state) => state.auth.accessToken);
    const { data } = yield call(
      axios.get,
      hostSearchURL + action.payload.host,
      {
        headers: apiHeaders(accessToken),
      }
    );

    if (!data) {
      yield put(actions.searchHostFailure("Host Id not found"));
    } else {
      // TODO Check if router and host are compatible.

      yield put(
        notificationActions.addNotification({
          type: "success",
          title: `Host found by Host Id ${action.payload.host}`,
        })
      );
      yield put(actions.searchHostSuccess(action.payload.host, data));
    }
  } catch (e: any) {
    if (e.response?.status === 404) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Host Id not found`,
        })
      );
      yield put(actions.searchHostFailure("Host Id not found"));
    } else {
      yield put(actions.searchHostFailure("Unknown error occured"));
    }
  }
}

export { reducer, actions, saga };
