import React, { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Separator, ActionButton, IIconProps } from "@fluentui/react";
import { RootState } from "../../store/reducers";
import AddHardware from "./AddHardware";
import RemoveHardware from "./RemoveHardware";
import AddCoach from "./AddCoach";
import AddPylon from "./AddPylon";
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import Card from "../Card";


const Maintenance = () => {
    const [currentScreen, setCurrentScreen] = React.useState('');
    const routerData = useSelector(
        (state: RootState) => state.router?.routerDetails
    );
    const routerSource = useSelector((state: RootState) => state.serial?.source);
    const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
    const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
    const dispatch = useDispatch();

    const addHardwareIcon: IIconProps = { iconName: 'DependencyAdd' };
    const removeHardwareIcon: IIconProps = { iconName: 'DependencyRemove' };
    const addCoachIcon: IIconProps = { iconName: 'BusSolid' };
    const addPylonIcon: IIconProps = { iconName: 'NetworkTower' };

    let breadcrumbItems: any[] = [];

    if (currentScreen === '') {
        breadcrumbItems.push({ text: 'Maintenance', key: 'a', isCurrentItem: true });
    } else {
        breadcrumbItems.push({ text: 'Maintenance', key: 'a', onClick: () => setCurrentScreen('') });
    }

    if (currentScreen === 'addHardware') {
        breadcrumbItems.push({ text: 'Add Hardware', key: 'b', isCurrentItem: true });
    }
    if (currentScreen === 'removeHardware') {
        breadcrumbItems.push({ text: 'Remove Hardware', key: 'b', isCurrentItem: true });
    }
    if (currentScreen === 'addCoach') {
        breadcrumbItems.push({ text: 'Add Coach', key: 'b', isCurrentItem: true });
    }
    if (currentScreen === 'addPylon') {
        breadcrumbItems.push({ text: 'Add Pylon', key: 'b', isCurrentItem: true });
    }


    return (
        <Card title={<Breadcrumb items={breadcrumbItems} /> as ReactElement} >
            {{
                '': (
                    <>
                        <div className="container--fluid">
                            <div className="container__col-12">
                                <Separator>Add / Remove Hardware</Separator>
                            </div>
                        </div>
                        <div className="container--fluid">
                            <div className="container__col-offset-4 container__col-4 maintenanceOption">
                                <ActionButton iconProps={addHardwareIcon} onClick={() => setCurrentScreen('addHardware')}>Add Hardware</ActionButton>
                            </div>
                        </div>
                        <div className="container--fluid">
                            <div className="container__col-offset-4 container__col-4 maintenanceOption">
                                <ActionButton iconProps={removeHardwareIcon} onClick={() => setCurrentScreen('removeHardware')}>Remove Hardware</ActionButton>
                            </div>
                        </div>
                        <div className="container--fluid">
                            <div className="container__col-12">
                                <Separator>Add Coach/Pylon</Separator>
                            </div>
                        </div>
                        <div className="container--fluid">
                            <div className="container__col-offset-4 container__col-4 maintenanceOption">
                                <ActionButton iconProps={addCoachIcon} onClick={() => setCurrentScreen('addCoach')}>Add Coach</ActionButton>
                            </div>
                        </div>
                        <div className="container--fluid">
                            <div className="container__col-offset-4 container__col-4 maintenanceOption">
                                <ActionButton iconProps={addPylonIcon} onClick={() => setCurrentScreen('addPylon')}>Add Pylon</ActionButton>
                            </div>
                        </div>
                    </>
                ),
                'addHardware': (<AddHardware />),
                'removeHardware': (<RemoveHardware />),
                'addCoach': (<AddCoach />),
                'addPylon': (<AddPylon />)
            }[currentScreen]}
        </Card >
    );
};

export default Maintenance;
