import React from "react";
import { Notifications } from "../Notifications/Notifications";
import Header from "../Header";
export interface FrameProps {
  children?: JSX.Element | JSX.Element[] | null;
}

const Frame = (props: FrameProps) => {
  return (
    <div className="frame container">
      <Header />
      <div className="container__row">
        <div className="container__col-sm-12">
          <Notifications />
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-md-3 hidden-sm-down" />
        <div
          className="container__col-12 container__col-sm-12 container__col-md-6"
          id="body"
        >
          {props.children}
        </div>
        <div className="container__col-md-3 hidden-sm-down" />
      </div>
    </div>
  );
};

export default Frame;
