import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Separator, DefaultButton, TextField } from "@fluentui/react";
import HostInputBox from "../HostInputBox";
import { RootState } from "../../store/reducers";
import { CoachType, actions as coachActions } from "../../ducks/maintenance";

const AddCoach = () => {
    const [newCoaches, setNewCoaches] = React.useState<CoachType[]>([{ id: '' }]);
    const routerData = useSelector(
        (state: RootState) => state.router?.routerDetails
    );
    const routerSource = useSelector((state: RootState) => state.serial?.source);
    const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
    const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
    const dispatch = useDispatch();

    const addNewRecord = () => {
        setNewCoaches([...newCoaches, { id: '' }])
    }

    const removeRecord = () => {
        setNewCoaches(newCoaches.slice(0, -1));
    }

    const updateRecord = (recordIndex: number, updateField: string) => (e: FormEvent, newValue?: string) => {
        const updatedCoaches = newCoaches.map((currentRecord, index) => {
            if (index === recordIndex) {
                return { ...currentRecord, [updateField]: newValue }
            } else {
                return currentRecord;
            }
        });
        setNewCoaches(updatedCoaches);
    }

    const completeForm = () => {
        newCoaches.forEach((coachRecord: CoachType, index: number) => {
            dispatch(coachActions.addCoach(coachRecord))
            setNewCoaches([{ id: '' }]);
        });
    }

    return (
        <>
            {newCoaches.map((newCoachesRecord, i) => (
                <div className="maintenanceRecord" key={i}>
                    <Separator>Coach #{i + 1}</Separator>
                    <HostInputBox inputLength={4} label="Coach ID" required onChange={updateRecord(i, 'id')} />
                </div>
            ))}
            <Separator />
            <div>
                <DefaultButton onClick={() => addNewRecord()}>+ More</DefaultButton> {' '}
                {(newCoaches.length > 1) && (
                    <DefaultButton onClick={() => removeRecord()}>- Less</DefaultButton>
                )}
                <DefaultButton onClick={completeForm} primary className="maintenanceFlushRight">Add Coaches</DefaultButton>
            </div>

        </>
    );
};

export default AddCoach;
