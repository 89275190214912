import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { actions as hostsActions } from "../../ducks/hosts";
import { actions as configurationActions } from "../../ducks/configuration";
import { isValidHost, lastNumber } from "../../constants";
import targetAccount from "../../configs/targetAccounts";
import Card from "../../components/Card";
import { responsiveRow } from "../../layout";

type HostRecordProps = {};

const HostRecord = (props: HostRecordProps) => {
  const host = useSelector((state: RootState) => state.hosts?.hostRecord);
  const router = useSelector((state: RootState) => state.router?.routerDetails);
  const configuration = useSelector((state: RootState) => state.configuration);
  const serial = useSelector((state: RootState) => state.serial);
  const [upgrade, setUpgrade] = useState(true);
  const [hideDialogRouter, setHideDialogRouter] = useState(true);
  const [hideDialogHost, setHideDialogHost] = useState(true);
  const dispatch = useDispatch();

  const doProvision = (force = false) => {
    return () => {
      if (!force) {
        if (isValidHost(router?.name)) {
          setHideDialogRouter(false);
          return;
        }
        if (host.assetId) {
          setHideDialogHost(false);
          return;
        }
        dispatch(configurationActions.provision(upgrade, router, host, serial));
      } else {
        setHideDialogHost(true);
        setHideDialogRouter(true);
        dispatch(configurationActions.provision(upgrade, router, host, serial));
      }
    };
  };

  const upgradeCheck = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    setUpgrade(isChecked || false);
  };

  if (!host?.hostID) {
    // Do not display if no router is loaded
    return null;
  }

  const requiresUpgrade = () => {
    // If the router in question is not  in the target account, an upgrade is required
    return lastNumber(router?.account) !== targetAccount;
  };

  return (
    <>
      <Card
        onClose={() => dispatch(hostsActions.clearHost())}
        iconName={host.hostType === "C" ? "BusSolid" : "NetworkTower"}
        title={`${host.hostType === "C" ? "Coach" : "Zone"} ${host.hostID}`}
        className="hostRecord"
      >
        <div className="section">
          {responsiveRow(
            host.hostType === "C" ? "Coach" : "Zone",
            host?.hostID
          )}
          {responsiveRow("Type", host.hostType === "C" ? "Coach" : "Pylon")}
          {responsiveRow(
            "Barcode",
            host?.assetId ? host?.assetId : "Unprovisioned"
          )}
        </div>
      </Card>
    </>
  );
};

export default HostRecord;
