import { all, takeEvery, put, call, delay, select } from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { debug, lastNumber, oneMoreIP } from "../constants";
import { APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";
import { actions as routerActions, routerPutUrl } from "./router";
import { actions as hostActions } from "./hosts";
import { actions as serialActions, serialSearchURL } from "./serial";
import { actions as notificationActions } from "./notifications";
import { actions as upgradeActions } from "./upgrades";
import { xMoreIP } from "../constants";
import buildTemplate from "../configs/routerConfigTemplates";
import targetAccount from "../configs/targetAccounts";
import { trackEvent } from "../configs/appInsights";
import packagej from "../../package.json";

type State =
  | {
    configurationId: string;
    configuration: any;
    isLoading: boolean;
    isProvisioning: boolean;
  }
  | undefined;

export const configurationSearchUrl = `${APIPrefix}router/config/`;
export const configurationPutUrl = `${APIPrefix}config/`;

const programVersion = `MARCApp v${packagej.version}`;

// Initial State
const initialState: State = {
  configurationId: "",
  configuration: {},
  isLoading: false,
  isProvisioning: false,
};

// Debug State
const debugState: State = {
  configurationId: "",
  configuration: {},
  isLoading: false,
  isProvisioning: false,
};

// Actions
const actionTypes = {
  getConfig: "CONFIG_GET",
  getConfigSuccess: "CONFIG_GET_SUCCESS",
  setConfig: "CONFIG_SET",
  provision: "CONFIG_PROVISION",
  deprovision: "CONFIG_DEPROVISION",
  setProvisioning: "CONFIG_SET_PROVISIONING",
};

const actions = {
  getConfig: () => action(actionTypes.getConfig),
  getConfigSuccess: (configuration: any) =>
    action(actionTypes.getConfigSuccess),
  setConfig: (template: string) => action(actionTypes.setConfig, { template }),
  isProvisioning: (provisioning: boolean) =>
    action(actionTypes.setProvisioning, { provisioning }),
  provision: (upgrade: boolean, router: any, host: any, serial: any, groupId?: number, bulk?: boolean) => action(actionTypes.provision, { upgrade, groupId, router, host, serial, bulk }),
  deprovision: (serialId: string) =>
    action(actionTypes.deprovision, { serialId }),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.getConfig]: (state, action) => ({
    ...state,
    isLoading: true,
  }),
  [actionTypes.getConfigSuccess]: (state, action) => ({
    ...state,
    configuration: action.payload.configuration,
    isLoading: false,
    lastError: "",
  }),
  [actionTypes.setProvisioning]: (state, action) => ({
    ...state,
    isProvisioning: action.payload.provisioning,
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([
    takeEvery(actionTypes.getConfig, waitGetConfig as any),
    takeEvery(actionTypes.provision, provision as any),
    takeEvery(actionTypes.deprovision, deprovision as any),
  ]);
};

function* waitGetConfig(action: { type: typeof actions.getConfig }) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const routerId = yield select((state) => state.router.Id);
    const { data } = yield call(axios.get, configurationSearchUrl + routerId, {
      headers: apiHeaders(accessToken),
    });

    if (!data) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Error getting routers configuration!`,
        })
      );
    } else {
      yield put(actions.getConfigSuccess(data[0]));
    }
  } catch (e: any) {
    if (e?.status === 404) {
      notificationActions.addNotification({
        type: "error",
        title: `Unable to find configuration for router`,
      });
    } else {
      notificationActions.addNotification({
        type: "error",
        title: `Unknown error occured: ${e.message}`,
      });
    }
  }
}

function* deprovision(action: {
  type: typeof actions.deprovision;
  payload: { serialId: string };
}) {
  const user = yield select((state) => state.auth.userData.account.name);
  trackEvent("Deprovision", {
    user,
    payload: action.payload,
  });
  const accessToken = yield select((state) => state.auth.accessToken);
  let { data } = yield call(
    axios.get,
    serialSearchURL + action.payload.serialId,
    {
      headers: apiHeaders(accessToken),
    }
  );
  if ((data || []).length !== 0) {
    const deprovisionRouterId = parseInt(data[0]?.id);
    const previousGroup = data[0]?.group;
    // Get routers configuration id
    data = yield call(axios.get, configurationSearchUrl + deprovisionRouterId, {
      headers: apiHeaders(accessToken),
    });
    if (!data) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Unable to deprovision previous router`,
        })
      );
    } else {
      const deprovisionRouterConfigurationId = data.data[0]?.id;
      // Reset configuration for device
      yield call(
        axios.put,
        configurationPutUrl + deprovisionRouterConfigurationId,
        {
          configuration: [
            {
              system: {
                asset_id: action.payload.serialId,
              },
            },
            [],
          ],
        }, // Empty device configuration
        {
          headers: apiHeaders(accessToken),
        }
      );
      // Reset routers provision stake
      yield call(
        axios.put,
        routerPutUrl + deprovisionRouterId,
        {
          account: `https://www.cradlepointecm.com/api/v2/accounts/${targetAccount}/`,
          group: previousGroup,
          name: `deprovisioned`,
          custom1: programVersion,
        },
        {
          headers: apiHeaders(accessToken),
        }
      );
      yield put(
        notificationActions.addNotification({
          type: "success",
          title: "Deprovisioned previous router",
        })
      );
    }
  } else {
    yield put(
      notificationActions.addNotification({
        type: "error",
        title: "Unable to find previously provisioned router",
      })
    );
  }
}

function* provision(action: {
  type: typeof actions.provision;
  payload: {
    upgrade: boolean,
    router: any,
    host: any,
    serial: any
    groupId?: number,
    bulk?: boolean
  };
}) {
  let router = action.payload?.router;
  const host = action.payload?.host;
  const serial = action.payload?.serial;
  const user = yield select((state) => state.auth.userData.account.name);
  const accessToken = yield select((state) => state.auth.accessToken);
  trackEvent("Provision", {
    user,
    payload: action.payload,
    router,
    host,
  });
  const bulk = action.payload?.bulk ?? false;

  if (!bulk) {
    yield put(actions.isProvisioning(true));
    router = yield select((state) => state.router);
  }

  if (host.assetId) {
    // Deprovision hosts previously provisioned router
    // Check if host has a provisioned router, if so then reset name & configuration
    const assetIds = host.assetId.split(",");
    for (const assetId of assetIds) {
      yield put(actions.deprovision(assetId));
      yield delay(1000); // Allow deprovisioning to get a head start
    }
    yield delay(3000); // Allow deprovisioning to get a head start
  }

  // Find target group for provisioning
  let targetGroup = lastNumber(router?.routerDetails?.group);
  if (action.payload?.groupId) {
    targetGroup = action.payload.groupId;
  }


  // Set routers account, group, description, and name to match its newly provisioned host
  yield call(
    axios.put,
    routerPutUrl + router.routerDetails.id,
    {
      account: `https://www.cradlepointecm.com/api/v2/accounts/${targetAccount}/`,
      group: `https://www.cralepointecm.com/api/v2/groups/${targetGroup}/`,
      name: `CP-KCM-${host.hostID}`,
      custom1: programVersion,
      description: host.lanSubnet,
    },
    {
      headers: apiHeaders(accessToken),
    }
  );

  // Get router configuration ID
  let data = yield call(
    axios.get,
    configurationSearchUrl + router.routerDetails.id,
    {
      headers: apiHeaders(accessToken),
    }
  );
  if (!data) {
    yield put(
      notificationActions.addNotification({
        type: "error",
        title: "Unable to find provisioning routers configuration",
      })
    );
  }

  const configurationId = data?.data[0].id;
  const lanID = uuidv4();
  const mainRouteTableID = uuidv4();
  const gpsID = uuidv4();
  const VMSVault = xMoreIP(host.vmsNet, 2);
  let template = {};
  if (host?.hostType === "C") {
    // Provision to a coach
    if (host.vmsNet === "") {
      // Coach has no VMS net
      template = buildTemplate(targetGroup, {
        ...host,
        name: `CP-KCM-${host.hostID}`,
        description: host.lanSubnet,
        asset_id: serial.serial,
        lanID,
        VMSVault,
      });
    } else {
      // Coach has VMS net
      const VMSID = Object.keys(data?.data[0].target[0].lan)[2];
      const VMSIP = oneMoreIP(host.vmsNet);
      template = buildTemplate(targetGroup, {
        ...host,
        name: `CP-KCM-${host.hostID}`,
        description: `${host.lanSubnet}`,
        asset_id: serial.serial,
        lanID,
        VMSID,
        VMSIP,
        VMSVault,
        mainRouteTableID,
        hostID: host.hostID,
        gpsID
      });
    }
  } else {
    // Provision to a pylon
    template = buildTemplate(targetGroup, {
      ...host,
      name: `CP-KCM-${host.hostID}`,
      description: host.lanSubnet,
      asset_id: serial.serial,
      lanID,
      VMSVault,
    });
  }

  // Set routers configuration
  yield call(
    axios.put,
    configurationPutUrl + configurationId,
    {
      suspended: false,
      synched: true,
      configuration: [template, []], // Set correct template
    },
    {
      headers: apiHeaders(accessToken),
    }
  );

  if (bulk) {
    yield put(upgradeActions.updateStatus(host.hostID, 'Configuration complete.', false, true));
  } else {
    yield put(
      notificationActions.addNotification({
        type: "success",
        title: "Router provisioned successfully",
      })
    );
    yield put(routerActions.clearRouter());
    yield put(serialActions.searchSerial(serial.serial, "provisioning"));
    yield put(hostActions.clearHost());
    yield put(hostActions.searchHost(host.hostID, "provisioning"));
    yield put(actions.isProvisioning(false));
  }


}

export { reducer, actions, saga };
