import { all, put, call, select, delay, takeEvery } from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { debug } from "../constants";
import { APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";
import { actions as routerActions } from "./router";
import { actions as notificationActions } from "./notifications";

type State =
  | {
    serial: string;
    mac: string;
    lastError: string;
    source: string;
    isLoading: boolean;
  }
  | undefined;

export const serialSearchURL = `${APIPrefix}serial/`;

// Initial State
const initialState: State = {
  serial: "",
  mac: "",
  lastError: "",
  source: "",
  isLoading: false,
};

// Debug State
const debugState: State = {
  serial: "",
  mac: "",
  lastError: "",
  source: "diagnostics",
  isLoading: false,
};

// Actions
const actionTypes = {
  searchSerial: "SERIAL_SEARCH",
  searchSerialSuccess: "SERIAL_SEARCH_SUCCESS",
  searchSerialFailure: "SERIAL_SEARCH_FAILURE",
  serialRefresh: "SERIAL_REFRESH",
};

const actions = {
  searchSerial: (serial: string, source: string) =>
    action(actionTypes.searchSerial, { serial, source }),
  searchSerialSuccess: (serial: string, mac: string) =>
    action(actionTypes.searchSerialSuccess, { serial, mac }),
  searchSerialFailure: (error: string) =>
    action(actionTypes.searchSerialFailure, { error }),
  serialRefresh: () => {
    action(actionTypes.serialRefresh);
  },
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.searchSerial]: (state, action) => ({
    ...state,
    serial: action.payload.serial,
    lastError: "",
    source: action.payload.source,
    isLoading: true,
  }),
  [actionTypes.searchSerialSuccess]: (state, action) => ({
    ...state,
    serial: action.payload.serial,
    mac: action.payload.mac,
    isLoading: false,
    lastError: "",
  }),
  [actionTypes.searchSerialFailure]: (state, action) => ({
    ...state,
    serial: "",
    mac: "",
    isLoading: false,
    lastError: action.payload.error,
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([
    takeEvery(actionTypes.searchSerial, waitSearchSerial as any),
    call(refreshLoop),
  ]);
};

function* refreshLoop() {
  while (true) {
    const serialState = yield select((state) => state.serial);
    if (serialState?.serial) {
      const accessToken = yield select((state) => state.auth.accessToken);
      const { data } = yield call(
        axios.get,
        serialSearchURL + serialState.serial,
        {
          headers: apiHeaders(accessToken),
        }
      );
      if ((data || []).length !== 0) {
        yield put(routerActions.refreshRouter(data[0]));
      }
    }
    yield delay(30000); // 30 sec
  }
}

function* waitSearchSerial(action: {
  type: typeof actions.searchSerial;
  payload: { serial: string, source: string };
}) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const { data } = yield call(
      axios.get,
      serialSearchURL + action.payload.serial,
      {
        headers: apiHeaders(accessToken),
      }
    );
    if ((data || []).length === 0) {
      notificationActions.addNotification({
        type: "error",
        title: `Serial # ${action.payload.serial} not found`,
      });
      yield put(actions.searchSerialFailure("Serial number not found"));
    } else {
      yield put(
        notificationActions.addNotification({
          type: "success",
          title: `Router found by serial # ${action.payload.serial}`,
        })
      );
      yield put(actions.searchSerialSuccess(action.payload.serial, data[0]));
      yield put(routerActions.loadRouter(data[0]));
    }
  } catch (e: any) {
    console.log("serial search error event", e);
    if (e.response?.status === 404) {
      yield put(
        notificationActions.addNotification({
          type: "error",
          title: `Serial # ${action.payload.serial} not found`,
        })
      );
      yield put(actions.searchSerialFailure("Serial not found"));
    } else {
      yield put(actions.searchSerialFailure("Unknown error occured"));
    }
  }
}

export { reducer, actions, saga };
