import { all, takeEvery, put, call } from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { debug } from "../constants";
import { APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";

// Types
export enum InitState {
  None,
  Pending,
  Failure,
  Success,
}

type State =
  | {
    apiInfo: string;
    initState: InitState;
  }
  | undefined;

const apiInfoURL = `${APIPrefix}/api-info`;

// Initial State
const initialState: State = {
  apiInfo: "",
  initState: InitState.None,
};

// Debug State
const debugState: State = {
  apiInfo: "Debug",
  initState: InitState.Success,
};

// Actions
const actionTypes = {
  getApiInfo: "INIT_API_INFO_GET",
  setApiInfo: "INIT_API_INFO_SET",
};

const actions = {
  getApiInfo: () => action(actionTypes.getApiInfo),
  setApiInfo: (apiInfo: string, newInitState: InitState) =>
    action(actionTypes.setApiInfo, { apiInfo, newInitState }),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.getApiInfo]: (state, action) => state,
  [actionTypes.setApiInfo]: (state, action) => ({
    ...state,
    apiInfo: action.payload.apiInfo,
    initState: action.payload.newInitState,
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([takeEvery(actionTypes.getApiInfo, waitApiInfo as any)]);
  if (!debug) {
    yield put(actions.getApiInfo());
  }
};

function* waitApiInfo(action: { type: typeof actions.getApiInfo }) {
  yield put(actions.setApiInfo("", InitState.Pending));
  try {
    const { data } = yield call(axios.get, apiInfoURL, {
      headers: apiHeaders(""),
    });
    yield put(actions.setApiInfo(data, InitState.Success));
  } catch (e) {
    yield put(actions.setApiInfo("", InitState.Failure));
  }
}

export { reducer, actions, saga };
