import {
  ApplicationInsights,
  IEventTelemetry,
  ICustomProperties,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const trackEvent = (event: string, props: ICustomProperties) => {
  const telemetry: IEventTelemetry = {
    name: event,
  };
  console.log("|| EVENT TRACKED ||", event);
  appInsights.trackEvent({
    name: event,
    properties: props,
  });
  appInsights.flush();
};

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "8e91e56d-ab67-410f-b0bc-c0badc239373",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export { reactPlugin, appInsights, trackEvent };
