export const msalConfig = {
  auth: {
    clientId:
      process.env.REACT_APP_ENV === "development"
        ? "63ce8573-1b66-4a46-8845-40198d3c58ed"
        : process.env.REACT_APP_ENV === "test"
        ? "ea9bcd32-e125-43e3-baf2-4754c7423109"
        : "328383f0-50ac-4168-9a95-019b310c3c55",
    authority:
      "https://login.microsoftonline.com/bae5059a-76f0-49d7-9996-72dfe95d69c7", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const sessionTTL = 60 * 60 * 60; // 1 hour

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  redirectUri: `${window.location.origin}/blank.html`,
  scopes: ["User.Read"],
};

// Scopes for tokens
export const tokenRequest = {
  scopes: [
    process.env.REACT_APP_ENV === "development"
      ? "api://Transit-MAR-Manager-APIDEV/.default"
      : process.env.REACT_APP_ENV === "test"
      ? "api://Transit-MAR-Manager-APITest/.default"
      : "api://Transit-MAR-Manager-APIProduction/.default",
  ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/transitiveMemberOf",
};
