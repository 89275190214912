import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Pivot, PivotItem } from "@fluentui/react";
import Frame from "../../components/Frame";
import SerialInput from "../../components/SerialInput";
import HostInput from "../../components/HostInput";
import HostIPTool from "../../components/HostIPTool";
import RouterRecord from "../../components/RouterRecord";
import HostRecord from "../../components/HostRecord";
import ProvisionButton from "../../components/ProvisionButton";
import Diagnostics from "../../components/Diagnostics";
import Maintenance from "../../components/Maintenance";
import Upgrades from "../../components/Upgrades";

import NoAccess from "../../components/NoAccess";
import { RootState } from "../../store/reducers";

const Dashboard = () => {
  const account = useSelector(
    (state: RootState) => state?.auth?.userData?.account
  );
  const host = useSelector((state: RootState) => state.hosts?.hostRecord);
  const router = useSelector((state: RootState) => state.router?.routerDetails);
  // const configuration = useSelector((state: RootState) => state.configuration);
  const [canProvision, setCanProvision] = useState<boolean>(false);
  const [canDiagnostics, setCanDiagnostics] = useState<boolean>(false);
  const [canMaintenance, setCanMaintenance] = useState<boolean>(false);
  const [canUpgrades, setCanUpgrades] = useState<boolean>(false);

  useEffect(() => {
    if (
      ((account && account.idTokenClaims?.roles) || []).some((item) =>
        [
          "Transit-MAR-Manager-SysAdmin",
          "Transit-MAR-Manager-ConfigAdmin",
          "Transit-MAR-Manager-ConfigUser",
        ].includes(item)
      )
    ) {
      setCanProvision(true);
    }
    if (
      ((account && account.idTokenClaims?.roles) || []).some((item) =>
        [
          "Transit-MAR-Manager-SysAdmin",
          "Transit-MAR-Manager-DiagUser",
        ].includes(item)
      )
    ) {
      setCanDiagnostics(true);
    }
    if (
      ((account && account.idTokenClaims?.roles) || []).some((item) =>
        ["Transit-MAR-Manager-SysAdmin",
          "Transit-MAR-Manager-ConfigAdmin"].includes(item)
      )
    ) {
      setCanMaintenance(true);
    }
    if (
      ((account && account.idTokenClaims?.roles) || []).some((item) =>
        ["Transit-MAR-Manager-SysAdmin",
          "Transit-MAR-Manager-ConfigAdmin"].includes(item)
      )
    ) {
      setCanUpgrades(true);
    }
  }, [account]);

  return canProvision || canDiagnostics ? (
    <Frame>
      <Pivot aria-label="Dashboard">
        {canProvision && (
          <PivotItem headerText="Provisioning">
            <br />
            <SerialInput source="provisioning" />
            {router?.id && <RouterRecord />}
            {router?.id && <HostInput source="provisioning" />}
            {host?.hostID && <HostRecord />}
            {host?.hostID && router?.id && <ProvisionButton />}
          </PivotItem>
        )}
        {canDiagnostics && (
          <PivotItem headerText="Diagnostics">
            <br />
            <SerialInput source="diagnostics" />
            <HostInput source="diagnostics" />
            {host?.hostID && <HostIPTool />}
            {router?.id && <Diagnostics />}
          </PivotItem>
        )}
        {canMaintenance && (
          <PivotItem headerText="Maintenance">
            <Maintenance />
          </PivotItem>
        )}
        {canUpgrades && (
          <PivotItem headerText="Upgrades">
            <Upgrades />
          </PivotItem>
        )}
      </Pivot>
    </Frame>
  ) : (
    <NoAccess />
  );
};

export default Dashboard;
