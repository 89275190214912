import React from "react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";

const Signal = ({ percent = 0, category = "none" }) => {
  if (percent === 0) {
    category = "non-existant";
  }
  return (
    <>
      <ProgressIndicator
        label={
          <>
            <FontIcon iconName="InternetSharing" /> &nbsp; Signal is {category}
          </>
        }
        percentComplete={percent / 100}
        barHeight={20}
        className="signal"
        description={`Strength is at ${percent}%`}
      />
    </>
  );
};

export default Signal;
