import React from "react";
import { Text, CompoundButton } from "@fluentui/react";
import Card from "../../components/Card";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configs/authConfig";

const Login = () => {
  const msal = useMsal();
  const logout = () => {
    const logoutRequest = {};
    msal.instance.logoutPopup(logoutRequest);
  };
  const login = () => {
    msal.instance.loginPopup(loginRequest);
  };
  return (
    <Card title="Please log in" className="login">
      <p>
        <Text variant="large">
          Welcome to the King County Mobile Access Routers Configuration
          Application
        </Text>
      </p>
      <p>
      <strong>Security Notice</strong><p />
<p>This system is the property of King County and is provided for authorized use only as defined in the King County
acceptable use of information technology assets policy. Any use of this computer system may be monitored
recorded audited and disclosed to authorized county and or law enforcement personnel. Any unauthorized or
improper use of this computer system may result in disciplinary actions as well as potential civil or legal penalties.
By using this computer system, you indicate your awareness and consent of the above information. If you do not
agree with these terms, please discontinue use of this computer system immediately.</p>
      </p>
      <p />
      <p>You are currently not authenticated.</p>
      <p>Please ensure no pop-ups are blocked and refresh the page to login</p>
      <p>
        You may need to{" "}
        <button onClick={logout}>
          LOGOUT
        </button>{" "}
        to{" "}
        <button onClick={login}>
          LOGIN
        </button>{" "}
        again.
      </p>
    </Card>
  );
};

export default Login;
