import { createReducer, action } from "typesafe-actions";
import { takeEvery, all } from "redux-saga/effects";
import { APIPrefix } from "../configs/apiConfig";
import { debug } from "../constants";
import { SagaIterator } from "redux-saga";

export const routerPutUrl = `${APIPrefix}router/`;

type State =
  | {
    routerDetails: any;
  }
  | undefined;

// Initial State
const initialState: State = {
  routerDetails: null,
};

// Debug State
const debugState: State = {
  routerDetails: {
    id: 1,
    name: "CP-KCM-2651",
    config_status: "synched",
    asset_id: 1394,
    full_product_name: "IBR-1700-600M",
    group: "https://www.cradlepointecm.com/api/v2/groups/316243/",
    device_type: "router",
    account: "https://www.cradlepointecm.com/api/v2/accounts/105348/",
    product: "https://www.cradlepointecm.com/api/v2/products/40/",
    mac: "00:30:44:32:A4:4B",
    description: "1394",
    state: "online",
  },
};

// Actions
const actionTypes = {
  loadRouter: "ROUTER_LOAD",
  setRouter: "ROUTER_SET",
  refreshRouter: "ROUTER_REFRESH",
  clearRouter: "ROUTER_CLEAR",
};

const actions = {
  loadRouter: (routerDetails: any) =>
    action(actionTypes.loadRouter, { routerDetails }),
  refreshRouter: (routerDetails: any) =>
    action(actionTypes.refreshRouter, { routerDetails }),
  setRouter: (routerDetails: any) =>
    action(actionTypes.setRouter, { routerDetails }),
  clearRouter: () => action(actionTypes.clearRouter),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.loadRouter]: (state, action) => ({
    ...state,
    routerDetails: action.payload.routerDetails,
  }),
  [actionTypes.refreshRouter]: (state, action) => ({
    ...state,
    routerDetails: action.payload.routerDetails,
  }),
  [actionTypes.clearRouter]: (state, action) => ({
    ...state,
    routerDetails: null,
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([takeEvery(actionTypes.setRouter, waitSetRouter as any)]);
};

function* waitSetRouter(action: {
  type: typeof actions.setRouter;
  payload: { routerDetails: any };
}) {
  // PUT routers endpoint
}

export { reducer, actions, saga };
