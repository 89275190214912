import React, { FormEvent } from 'react'
import { TextField } from "@fluentui/react";

type HostInputBoxProps = {
    inputLength: number;
    label: string;
    required?: boolean;
    onChange: (event: FormEvent, newInput: string) => void;
}

const HostInputBox = (props: HostInputBoxProps) => {
    const [myVal, setMyVal] = React.useState<string>('0'.repeat(props.inputLength));

    const inputChanged = (event: FormEvent, eventInput?: string) => {
        if (eventInput) {
            let newInput = '';
            if (eventInput.length !== props.inputLength + 1) {
                newInput = `000000${eventInput}`;
                newInput = newInput.slice(newInput.length - props.inputLength);
            } else {
                newInput = eventInput.slice(eventInput.length - props.inputLength, eventInput.length);
            }

            setMyVal(newInput);
            props.onChange(event, newInput);
        }
    }

    return (
        <>
            <TextField label={props.label} required={props.required} onChange={inputChanged} value={myVal} />
        </>
    )
}

export default HostInputBox;