import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RouterRecord from "../RouterRecord";
import { actions as diagnosticsActions } from "../../ducks/diagnostics";
import { RootState } from "../../store/reducers";
import { xMoreIP } from "../../constants";
import DeviceCard from "./DeviceCard";
import PingCard from "./PingCard";

const Diagnostics = () => {
  const routerData = useSelector(
    (state: RootState) => state.router?.routerDetails
  );
  const routerSource = useSelector((state: RootState) => state.serial?.source);
  const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
  const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
  const dispatch = useDispatch();
  useEffect(() => {
    if (routerSource === "diagnostics") {
      dispatch(diagnosticsActions.getDevices(routerData?.id));
    }    
  }, [routerData?.id, routerSource, dispatch]);

  useEffect(() => {
    dispatch(diagnosticsActions.statusGet(routerData?.name));
  }, [routerData?.name])

  const displayDevices = (diagnosticsData?.devices || []).filter((device) => {
    if (device.name === "ethernet-wan") {
      return false;
    }
    if (device.mode === "lan") {
      return false;
    }
    return true;
  });
  const startPing = () => {
    /**
   * Copilot = 192.168.243.1
   * Touchit DDU = 192.168.243.2
   * Router IP = 192.168.243.3
   */
    dispatch(diagnosticsActions.startPing(routerData?.name, ["192.168.243.1", "192.168.243.2", "192.168.243.3", xMoreIP(routerData?.description, 1), xMoreIP(routerData?.description, 2), xMoreIP(routerData?.description, 3), xMoreIP(routerData?.description, 4), xMoreIP(routerData?.description, 5)]));
  };

  return (
    <>
      <h1>Router</h1>
      <RouterRecord />
      <PingCard startPing={startPing}/>
      {displayDevices.length > 0 && (
        <>
          <h2>Internal Modems</h2>
          {displayDevices
            .filter((findDevice) => findDevice.port === "int1")
            .map((device) => (
              <DeviceCard
                device={device}
                devicesHealth={diagnosticsData.devicesHealth}
              />
            ))}
          <h2>MC400 Modems</h2>
          {displayDevices
            .filter((findDevice) => findDevice.port === "modem1")
            .map((device) => (
              <DeviceCard
                device={device}
                devicesHealth={diagnosticsData.devicesHealth}
              />
            ))}
          <h2>Wifi</h2>
          {displayDevices
            .filter((findDevice) => findDevice.service_type === "WiFi")
            .map((device) => (
              <DeviceCard
                device={device}
                devicesHealth={diagnosticsData.devicesHealth}
              />
            ))}
        </>
      )}
    </>
  );
};

export default Diagnostics;
