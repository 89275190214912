import React, { FormEvent, TextareaHTMLAttributes, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { ButtonGrid } from "@fluentui/react";
import { TextField, Separator, DefaultButton } from "@fluentui/react";
import { HardwareType, actions as hardwareActions } from "../../ducks/maintenance";

const AddHardware = () => {
    const [newHardware, setNewHardware] = React.useState<HardwareType[]>([{ barcode: '', mac: '' }]);
    const routerData = useSelector(
        (state: RootState) => state.router?.routerDetails
    );
    const routerSource = useSelector((state: RootState) => state.serial?.source);
    const diagnosticsData = useSelector((state: RootState) => state.diagnostics);
    const certData = useSelector((state: RootState) => state.diagnostics?.status?.certmgmt)
    const dispatch = useDispatch();

    const addNewRecord = () => {
        setNewHardware([...newHardware, { barcode: '', mac: '' }])
    }

    const removeRecord = () => {
        setNewHardware(newHardware.slice(0, -1));
    }

    const updateRecord = (recordIndex: number, updateField: string) => (e: FormEvent, newValue?: string) => setNewHardware(newHardware.map((currentRecord, index) => (index === recordIndex) ? { ...currentRecord, [updateField]: newValue } : currentRecord));

    const completeForm = () => {
        newHardware.forEach((newHardwareRecord, index) => {
            dispatch(hardwareActions.addHardware(newHardwareRecord));
        });
    }

    return (
        <>
            {newHardware.map((newHardwareRecord, i) => (
                <div className="maintenanceRecord" key={i}>
                    <Separator>Hardware #{i + 1}</Separator>
                    <TextField label="Barcode" required onChange={updateRecord(i, 'barcode')} />
                    <TextField label="MAC Address" required onChange={updateRecord(i, 'mac')} />
                </div>
            ))}
            <Separator />
            <div>
                <DefaultButton onClick={() => addNewRecord()}>+ More</DefaultButton>{' '}
                {(newHardware.length > 1) && (
                    <DefaultButton onClick={() => removeRecord()}>- Less</DefaultButton>
                )}
                <DefaultButton onClick={() => completeForm()} primary className="maintenanceFlushRight">Add New Hardware</DefaultButton>
            </div>
        </>

    );
};

export default AddHardware;
