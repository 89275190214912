import { all, takeEvery, put, call, select, delay } from "redux-saga/effects";
import { createReducer, action } from "typesafe-actions";
import axios from "axios";
import { debug } from "../constants";
import { APIPrefix, apiHeaders } from "../configs/apiConfig";
import { SagaIterator } from "redux-saga";
import { actions as authActions, actionTypes as authActionTypes } from "./auth";
import { AccountInfo } from "@azure/msal-browser";

type GroupType = {
  id: string;
  name: string;
  target_firmware: string;
};

type AvailableGroupType = {
  groupName: string;
  target_firmware: string;
  ncM_GroupID: number;
  hosttype: string;
}

type State =
  | {
    groups: GroupType[];
    availableGroups: AvailableGroupType[];
  }
  | undefined;

const groupsURL = `${APIPrefix}groups/`;
const groupsAvailableURL = `${APIPrefix}groups/available`;

// Initial State
const initialState: State = {
  groups: [],
  availableGroups: []
};

// Debug State
const debugState: State = {
  groups: [
    {
      id: "316243",
      name: "PROD-TEST-PROV-7.22.40",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4671/",
    },
    {
      id: "316244",
      name: "PROD-TEST-PROV-7.22.60",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4671/",
    },
    {
      id: "316250",
      name: "PROD-TEST-PROV-7.22.30",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4593/",
    },
  ],
  availableGroups: [
    {
      ncM_GroupID: 316243,
      groupName: "ProdTest",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4671/",
      hosttype: 'C'
    },
    {
      ncM_GroupID: 316244,
      groupName: "ProdTest VMS",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4671/",
      hosttype: 'C'
    },
    {
      ncM_GroupID: 316250,
      groupName: "PylonProtest",
      target_firmware: "https://www.cradlepointecm.com/api/v2/firmwares/4593/",
      hosttype: 'P'
    },

  ]
};

// Actions
const actionTypes = {
  loadAvailableGroups: "GROUPS_AVAILABLE_LOAD",
  loadGroups: "GROUPS_LOAD",
  clearGroups: "GROUPS_CLEAR",
};

const actions = {
  loadGroups: (groups: GroupType[]) =>
    action(actionTypes.loadGroups, { groups }),
  loadAvailableGroups: (groups: AvailableGroupType[]) =>
    action(actionTypes.loadAvailableGroups, { groups }),
  clearGroups: () => action(actionTypes.clearGroups),
};

const reducer = createReducer(debug ? debugState : initialState, {
  [actionTypes.loadGroups]: (state, action) => ({
    ...state,
    groups: action.payload.groups,
  }),
  [actionTypes.loadAvailableGroups]: (state, action) => ({
    ...state,
    availableGroups: action.payload.groups,
  }),
  [actionTypes.clearGroups]: (state, action) => ({
    ...state,
    groups: [],
  }),
});

// Saga
const saga = function* (): SagaIterator {
  yield all([takeEvery(authActionTypes.setAccount, waitAccount as any)]);
};

function* waitAccount(action: {
  type: typeof authActions.setAccount;
  payload: { account: AccountInfo };
}) {
  yield delay(2000);

  const accessToken = yield select((state) => state.auth.accessToken);
  const { data } = yield call(axios.get, groupsURL, {
    headers: apiHeaders(accessToken),
  });
  if ((data || []).length === 0) {
    // Nothing to do
  } else {
    // TODO - filter all groups to only those in the targetAccount
    yield put(actions.loadGroups(data));
  }

  const { data: availableData } = yield call(axios.get, groupsAvailableURL, {
    headers: apiHeaders(accessToken),
  });
  if ((data || []).length === 0) {
    // Nothing to do
  } else {
    // TODO - filter all groups to only those in the targetAccount
    yield put(actions.loadAvailableGroups(availableData));
  }
}

export { reducer, actions, saga };
